.profile_input {
    border: 1px solid #0b4374;
    background-color: #fff;
}
.profile_header {
    /* background-color: #0b4374; */
    height: 70px;
    position: relative;
}
.upload_circle {
    width: 200px;
    height: 200px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, 0);
    box-shadow: 0px 3px 6px #00000029;
}
.upload_circle span {
    position: absolute;
    text-align: center;
    color: #0b4374;
    top: 35%;
    left: 0;
    right: auto;
    bottom: auto;
    transform: translate(0, 0);
    font-size: 14px;
}
.reset-btn {
    border: none;
    background-color: transparent;
    color: #0b4475;
    padding: 10px;
    margin-top: 10px;
    margin-left: 10px;
    outline: none;
}
.react_modal {
    width: 400px;
    margin: auto;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal_overlay {
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal_content {
    display: flex;
    flex-direction: column;
}

.modal_content h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.form_group {
    margin-bottom: 15px;
}

.form_group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    color: #333;
}

.form_group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
    box-sizing: border-box;
    transition: border-color 0.3s;
}

.form_group input:focus {
    border-color: #0b4475;
    outline: none;
}

.modal_actions {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.btn_primary {
    background-color: #0b4475;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.btn_primary:hover {
    background-color: #0056b3;
}

.btn_secondary {
    background-color: white;
    /* color: white; */
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s;
}

.btn_secondary:hover {
    background-color: #fff;
}

.error_text {
    color: red;
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 10px;
}

/*Instructor css*/
.head-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #eeeeee;
    padding: 20px 0px 20px 0px;
}
.head-wrap .dropdown .btn {
    background-color: white;
    color: #0b4475;
    width: 150px;
}
.head-wrap .dropdown .dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.head-wrap .dropdown .btn-secondary:focus {
    box-shadow: none;
}
.head-text {
    font-weight: 700;
    font-size: 20px;
}
.income {
    display: flex;
    justify-content: space-evenly;
    margin: 20px -15px;
}
.income-table {
    margin: 0px 15px;
    width: calc(60% - 30px);
}
.income-header {
    display: flex;
    justify-content: space-evenly;
    font-weight: bold;
}
.income-data {
    display: flex;
    align-items: stretch;
    box-shadow: 0px 0px 4px #00000029;
    border-radius: 6px;
    margin-top: 20px;
}
.income-row {
    flex: 1;
    text-align: center;
    padding: 20px 5px;
}
.card1-inst {
    width: calc(40% - 30px);
    height: 240px;
    margin: 0px 15px;
}
.table-instructor {
    /* padding: 12px 0px 12px 12px; */
    border-bottom: 2px solid #eeeeee;
}
.table-responsive {
    display: table;
    margin-top: 20px;
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    .profile_header {
        /* background-color: #0b4374; */
        height: 80px;
    }
    .upload_circle {
        width: 150px;
        height: 150px;
        top: 80%;
    }
}
@media screen and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
    .profile_header {
        /* background-color: #0b4374; */
        height: 80px;
    }
    .upload_circle {
        width: 150px;
        height: 150px;
        top: -50%;
        left: 50%;
    }
    .income {
        display: block;
    }
    .income-table {
        margin: 0px;
        width: auto;
    }
    .card1-inst {
        width: auto;
        height: 200px;
        margin: 20px 0px;
    }
    .table-responsive {
        display: block;
    }
    .head-text {
        font-size: 16px;
    }
    .head-wrap .dropdown .btn {
        width: auto;
    }
}
