.chatbot {
    width: 350px;
    height: 400px;
    background: #0b4475;
    border-radius: 16px;
    position: fixed;
    bottom: 100px;
    right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.tutor {
    /* width: 350px; */
    width: 100%;
    height: 400px;
    background: #0b4475;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.tutor-dialog {
    background: white;
    height: 330px;
    /* width: 330px; */
    width: 97%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 5px;
    overflow-y: scroll;
}

.chatbot-dialog {
    background: white;
    height: 330px;
    width: 330px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 5px;
    overflow-y: scroll;
}

.chatbot-input {
    height: 44px;
    width: 332px;
    background: #0b4475;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}

.tutor-input {
    height: 44px;
    width: 97%;
    background: #0b4475;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}

.chatbot-input-text {
    width: 100%;
    height: 45px;
    border-bottom-left-radius: 8px;
    border: 2px solid #0b4475;
    font-size: 15px;
}

.chatbot-input-text:disabled {
    background: grey;
}

.chatbot-input-text:focus {
    outline: none;
}

.chatbot-input-submit {
    color: white;
    /* background: white; */
    font-size: 40px;
    border-bottom-right-radius: 8px;
    transition: 0.2s;
}

.chatbot-input-submit:hover {
    cursor: pointer;
    color: #7cbbff;
    transition: 0.2s;
}

.conversation-component {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: fit-content;
    margin-top: 20px;
    border-radius: 8px;
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.conversation-component:first-of-type {
    margin: 0px;
}

.conversation-ai-img {
    width: 40px;
    margin-right: 5px;
    align-self: flex-start;
}

.conversation-text {
    font-size: 14px;
    margin-right: 2px;
}

.chatbot-bottom-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.please-wait {
    width: 100%;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 20px;
}

/* .response-container{
    display: flex;
    flex-direction: row;
    align-items: center;
} */

.ellipsis-anim span {
    font-size: 18px;
    opacity: 0;
    -webkit-animation: ellipsis-dot 1s infinite;
    animation: ellipsis-dot 1s infinite;
}

.ellipsis-anim span:nth-child(1) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}
.ellipsis-anim span:nth-child(2) {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}
.ellipsis-anim span:nth-child(3) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

@-webkit-keyframes ellipsis-dot {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes ellipsis-dot {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
