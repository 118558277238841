header {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 0 20px;
    box-shadow: 0px 3px 6px #00000029;
}

.logo a {
    color: #000;
    display: flex;
    align-items: center;
}

.logo img {
    width: 100px;
}

.logo span {
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
}

.top_category {
    display: flex;
    width: auto;
    position: relative;
}

.category_button {
    display: flex;
    align-items: center;
    width: auto;
}

.mm_category_button {
    display: none;
    align-items: center;
    width: auto;
    width: 100%;
    color: #000;
    padding: 10px 0px 10px 0px;
    border-bottom: 1px solid #dfdfdf;
    font-weight: 600;
    transition: 0.3s all ease;
}

.category_button figure,
.mm_category_button figure {
    width: 24px;
}

.category_button span,
.mm_category_button span {
    font-weight: 600;
    line-height: 1;
}

.search {
    margin: 0 30px;
    flex: 1;
    min-width: 1px;
    width: 100%;
    align-self: center;
    position: relative;
}

.search_input {
    position: relative;
}

.search_magnify {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    width: 20px;
}

.search_input .form-control {
    padding: 10px 20px 10px 60px;
}

.reset {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
}

.reset::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 14px;
    height: 1px;
    background-color: #ff0000;
}

.reset::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 14px;
    height: 1px;
    background-color: #ff0000;
}

.search_dropdown {
    position: absolute;
    background-color: #fff;
    border-radius: 10px;
    top: 44px;
    left: 0;
    z-index: 99;
    box-shadow: 0 3px 6px #00000029;
    width: 100%;
    padding: 10px 0;
    display: none;
}

.search_row {
    display: flex;
    align-items: flex-start;
    padding: 10px 20px;
}

.search_row figure {
    width: 50px;
    border-radius: 5px;
    overflow: hidden;
}

.search_row_details {
    padding-left: 15px;
    width: calc(100% - 50px);
    white-space: normal;
}

.search_row_details h4 {
    font-size: 13px;
    margin-bottom: 0;
}

.search_row_details span {
    font-size: 11px;
    color: #666;
    margin-right: 10px;
}

header .link_black {
    align-self: center;
}

.top_cart {
    position: relative;
    display: flex;
    align-items: center;
}

.top_cart_wrapper {
    align-self: center;
    position: relative;
    cursor: pointer;
}

.top_login {
    margin-left: 20px;
    align-self: center;
}

.language_btn {
    width: 44px;
    height: 44px;
    border-radius: 100px;
    box-shadow: 0 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin-left: 20px;
    align-self: center;
}

.hamburger {
    display: none;
    width: 30px;
    margin-left: 20px;
    align-items: flex-end;
    flex-direction: column;
}

.hamburger span {
    display: inline-block;
    width: 100%;
    height: 2px;
    background-color: #000;
    margin: 3px 0;
}

.hamburger span:nth-child(2) {
    width: 80%;
}

.top_order_1 {
    width: auto;
    padding: 10px 0;
}

.top_order_2 {
    width: auto;
    display: flex;
    align-items: stretch;
    white-space: nowrap;
}

.top_order_3 {
    flex: 1;
    min-width: 1px;
    width: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin: 0 30px;
}

.mobile_menu {
    position: fixed;
    right: -200%;
    top: 0;
    width: 300px;
    height: 100%;
    padding: 30px;
    background-color: #fff;
    z-index: 99;
    box-shadow: 0 -3px 6px #00000029;
    display: flex;
    flex-direction: column;
    transition: 0.3s all ease;
}

.mobile_menu.show {
    right: 0;
}

.mm_close {
    display: inline-block;
    width: 16px;
    margin-left: auto;
    cursor: pointer;
}

.mm_inner {
    margin-top: 20px;
}

.mobile_menu a {
    width: 100%;
    color: #000;
    display: inline-block;
    padding: 10px;
    border-bottom: 1px solid #dfdfdf;
    font-weight: 600;
    transition: 0.3s all ease;
}

.mobile_menu a:hover {
    background-color: #0b4475;
    color: #fff;
}

.top_notification {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.notification_bell {
    align-self: center;
    position: relative;
    cursor: pointer;
}

.top_cart figure,
.notification_bell figure {
    width: 24px;
}

.cart_count,
.notification_bubble {
    background-color: #1995bf;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    position: absolute;
    right: -8px;
    top: -8px;
    font-size: 11px;
    padding-top: 2px;
    font-weight: 600;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button_primary {
    background-color: #0b4475;
    padding: 10px;
    width: 100%;
}

.button_primary:hover {
    background-color: #0b4475;
}

.QAButton {
    background-color: #0b4475;
    padding: 10px 20px;
}

.QAButton:hover {
    background-color: #0b4475;
}

.top_profile {
    display: flex;
    align-items: center;
    position: relative;
}

.top_profile_img {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #1392be;
    color: #fff;
    text-transform: uppercase;
    overflow: hidden;
    margin-left: 20px;
    cursor: pointer;
}

.dropdown_inner {
    display: inline-block;
    width: 100%;
    max-height: 350px;
    overflow-y: auto;
}

.dropdown-menu {
    border: none;
    padding: 0;
    margin: 0;
    right: 0 !important;
    left: auto !important;
    transform: none !important;
}

.cart_dropdown {
    position: absolute;
    background-color: #fff;
    border-radius: 10px;
    top: 60px !important;
    right: -100px !important;
    z-index: 99;
    box-shadow: 0 3px 6px #00000029;
    width: 400px;
    text-align: center;
    padding: 20px;
    border: 1px solid #0b4475;
}

.keepShpping {
    color: #0b4475;
    font-weight: 900;
    margin-top: 20px;
}

.cart_row {
    display: flex;
    align-items: flex-start;
    padding: 15px;
}

.cart_img {
    width: 80px;
}

.cart_course_details {
    flex: 1;
    min-width: 1px;
    width: 100%;
    padding-left: 15px;
    white-space: normal;
    color: #000;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.cart_course_title {
    font-size: 14px;
    font-weight: 800;
    margin-bottom: 5px;
    color: #000;
}

.cart_course_instructor {
    font-size: 12px;
    font-weight: 600;
}

.cart_course_details .cp_wrapper {
    font-size: 13px;
    margin-bottom: 0;
}

.cart_course_details .price_strike {
    font-size: 11px;
}

.dropdown_checkout {
    padding: 15px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border-top: 1px solid #eee;
}

.dropdown_checkout strong {
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
    font-size: 18px;
}

.profile_dropdown {
    position: absolute;
    background-color: #fff;
    border-radius: 10px;
    top: 80px !important;
    right: 0;
    z-index: 2000;
    box-shadow: 0 3px 6px #00000029;
    display: none;
}

.pd_top {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid #eee;
}

.pd_top .top_profile_img {
    width: 60px;
    height: 60px;
    font-weight: 800;
    font-size: 30px;
    margin: 0;
}

.pd_ne {
    width: 100%;
    flex: 1;
    min-width: 1px;
    padding-left: 15px;
}

.pd_name {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 10px;
    line-height: 1;
}

.pd_email {
    color: #808080;
    font-size: 14px;
    line-height: 1;
}

.pd_bottom {
    padding: 0 35px;
}

.pd_bottom a {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 20px 0;
    color: #000;
    font-weight: 600;
}

.pd_bottom span {
    width: 18px;
    margin-right: 15px;
}

.notify_dropdown {
    position: absolute;
    background-color: #fff;
    border-radius: 10px;
    top: 80px !important;
    right: 0;
    z-index: 99;
    box-shadow: 0 3px 6px #00000029;
    padding: 20px;
    width: 400px;
    display: none;
}

.notify_dropdown h3 {
    color: #0b4475;
    font-size: 18px;
    margin-bottom: 10px;
}

.notify_row {
    display: flex;
    align-items: flex-start;
    padding: 5px 0;
}

.notify_row figure {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    overflow: hidden;
}

.notify_content {
    font-size: 13px;
    margin: 0 20px;
    width: 100%;
    flex: 1;
    min-width: 1px;
    white-space: normal;
}

.notify_content p {
    margin-bottom: 5px;
    color: #000;
}

.notify_content span {
    color: #808080;
}

.notify_unread p {
    font-weight: 600;
}

.notify_bubble {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: #0b4475;
}

.category_menu {
    position: absolute;
    top: 20px;
    left: 0;
    z-index: 100;
    align-items: flex-start;
    display: none;
}

.top_category:hover .category_menu {
    display: flex;
}

.menu_topic {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 6px #00000029;
}

.menu_item {
    display: inline-block;
    width: 100%;
    min-width: 200px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
}

.menu_link {
    padding: 10px 50px 10px 20px;
    color: #808080;
    display: inline-block;
    width: 100%;
    position: relative;
}

.arrow-icon {
    color: #0b4475;
    content: '';
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    border: solid #0b4475;
    border-width: 2px 2px 0 0;
    width: 8px;
    height: 8px;
}

/* .menu_link::before {
  content: "";
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  border: solid #0b4475;
  border-width: 2px 2px 0 0;
  width: 8px;
  height: 8px;
} */
.menu_link:hover {
    background-color: #d6ecff;
    color: #0b4475;
}

.sub_menu_topic {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 6px #00000029;
}

.sub_menu {
    position: absolute;
    top: 0px;
    left: calc(100% - 5px);
    z-index: 100;
    align-items: flex-start;
    display: none;
}

.menu_item:hover > .sub_menu {
    display: block;
}

.sub_menu_item {
    display: inline-block;
    width: 100%;
    min-width: 200px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
}

.sub_menu_item:first-child .sub_menu_link {
    border-radius: 10px 10px 0 0;
}

.sub_menu_item:last-child .sub_menu_link {
    border-radius: 0 0 10px 10px;
}

.sub_menu_link {
    padding: 10px 50px 10px 20px;
    color: #808080;
    display: inline-block;
    width: 100%;
    position: relative;
}

/* .sub_menu_link::before {
  content: "";
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  border: solid #0b4475;
  border-width: 2px 2px 0 0;
  width: 8px;
  height: 8px;
} */
.sub_menu_link:hover {
    background-color: #d6ecff;
    color: #0b4475;
}

.mm_menu_topic {
    background-color: #fff;
}

.mm_menu_item {
    display: inline-block;
    width: 100%;
    min-width: 200px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    border-bottom: 1px solid #dfdfdf;
}

.mm_menu_tab {
    display: flex;
    justify-content: 'space-between';
    width: 100%;
}

.mm_menu_item .mm_menu_tab > a {
    width: calc(100%-20px);
    border-bottom: 0px !important;
}

.mm_menu_link {
    padding: 10px 50px 10px 20px;
    color: #808080;
    display: inline-block;
    width: 20px;
    position: relative;
}

.mm_menu_link::before {
    content: '';
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    border: solid #0b4475;
    border-width: 2px 2px 0 0;
    width: 8px;
    height: 8px;
}

.sub_mm_menu_topic {
    background-color: #fff;
}

.sub_mm_menu {
    width: 100%;
    z-index: 100;
    align-items: flex-start;
}

.sub_mm_menu_item {
    display: inline-block;
    width: 100%;
    min-width: 200px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
}

.sub_mm_menu_item:last-child .sub_mm_menu_link {
    border-bottom: 0px !important;
}

.sub_mm_menu_link {
    padding: 10px 50px 10px 20px;
    color: #808080;
    display: inline-block;
    width: 100%;
    position: relative;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .logo img {
        width: 70px;
    }

    .logo span {
        font-size: 13px;
    }

    .language_btn {
        margin-left: 10px;
        width: 40px;
        height: 40px;
    }

    .top_login {
        margin-left: 15px;
        font-size: 13px;
    }

    header .link_black {
        font-size: 13px;
    }
}

@media screen and (max-width: 991px) {
    header {
        padding: 0 15px;
    }

    .notify_dropdown,
    .profile_dropdown {
        top: 62px !important;
    }

    .cart_dropdown {
        top: 100px !important;
    }
}

@media screen and (max-width: 767px) {
    header {
        flex-wrap: wrap;
        box-shadow: none;
        margin-bottom: 20px;
    }

    .category_button {
        display: none;
    }

    .top_order_1 {
        width: 50%;
        order: 1;
    }

    .top_order_2 {
        order: 2;
        width: 50%;
        justify-content: flex-end;
        align-items: center;
    }

    .top_order_3 {
        order: 3;
        flex: 1;
        min-width: 1px;
        width: 100%;
        margin: 20px 0 0;
    }

    .hamburger {
        display: inline-flex;
    }

    .dropdown-menu {
        position: fixed !important;
    }

    .cart_dropdown,
    .notify_dropdown {
        width: 350px;
        right: 0 !important;
    }

    .cart_dropdown,
    .notify_dropdown,
    .profile_dropdown {
        top: 50px !important;
    }

    .search_magnify {
        display: none;
    }

    .top_category {
        left: 93px;
        top: -68px;
    }

    .top_order_3 .link_black {
        display: none;
    }

    /* .category_button{
    display: none;
  } */

    .category_menu {
        top: 40px;
    }

    .search {
        margin: 0;
    }

    .logo img {
        width: 50px;
    }

    .logo span {
        font-size: 14px;
    }

    .search_input .form-control {
        padding: 10px 20px;
    }

    .language_btn {
        width: 26px;
        height: 26px;
        font-size: 14px;
        margin-left: 15px;
    }

    .top_login.btn_primary {
        background-color: transparent;
        /* padding: 0; */
        color: #fff;
        border: none;
        margin-left: 15px;
    }
}
