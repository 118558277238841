.connect-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.connect-container > h1 {
    /* color: #0B4475; */
    font-weight: bold;
    font-size: 24px;
}

.connect-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    overflow-y: scroll;
    height: 90%;
    overflow-x: hidden;
}

.connect-item {
    border: 1px solid #0B4475;
    padding: 10px;
    border-radius: 5px;
    width: 98%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.connect-item > button {
    background-color: #0B4475;
    color: white;
    border: none;
    padding: 5px;
    border-radius: 5px;
    width: 80px;
}

.connect-item-name {
    font-weight: bold;
    /* color: #0B4475; */
    width: 200px;
}

.chat-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

.back-button {
    background: none;
    border: none;
    color: #0B4475;
    font-weight: bold;
    cursor: pointer;
    padding: 10px 0;
    font-size: 24px;
}

.back-button:hover {
    color: #1580DC;
}

.message_section {
    margin-top: 20px;
}

.messages {
    border: 1px solid #eee;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    width: 100%;
}

.msg_header {
    padding: 20px;
    border-bottom: 1px solid #eee;
}

.msg_win {
    padding: 20px;
    height: 250px !important;
    overflow-y: scroll;
}

.msg_footer {
    padding: 20px;
    border-top: 1px solid #eee;
}

.msg_input {
    display: flex;
    align-items: center;
}

.msg_input form {
    display: flex;
    align-items: center;
    width: 100%;
}

.msg_input input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-right: 10px;
}

@media (max-width: 965px) {
    .connect-container {
        height: 700px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .container {
        margin-top: 50px;
    }

    .chat-wrapper {
        height: 700px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 20px;
    }

    .message_section {
        height: calc(100% - 60px);
        margin-top: 0;
    }

    .messages {
        height: calc(100% - 40px);
    }

    .msg_win {
        height: 350px !important;
    }
}

@media (max-width: 440px) {
    .connect-item {
        width: 98%;
    }
}

@media (max-width: 349px) {
    .connect-item {
        flex-direction: column;
        width: 70%;
    }

    .connect-item button {
        margin-top: 10px;
    }
}

@media (max-width: 767px) {
    .connect-container {
        width: 100%;
        padding: 0;
    }

    .container {
        max-width: 100%;
    }

    .chat-wrapper {
        padding: 10px;
        width: 100%;
        left: 0;
        right: 0;
    }

    .message_section {
        padding: 0;
        width: 100%;
        margin: 0 !important;
    }

    .messages {
        max-width: 100%;
        width: 100%;
        margin: 0;
        border-radius: 5px;
    }

    .msg_header,
    .msg_footer,
    .msg_win {
        width: 100%;
    }

    .msg_input {
        width: 100%;
    }

    .msg_input form {
        width: 100%;
        margin: 0;
    }
}

@media (max-width: 304px) {
    .container {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}