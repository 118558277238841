.instructor-dashboard_home {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 10px;
    padding-left: 20px;
    gap: 30px;
}

.instructor-dashboard_home > h2 {
    font-weight: bold;
    word-wrap: break-word;
    font-size: 25px;
}

.instructor-dashboard_content_total_students_title,
.instructor-dashboard_content_total_courses_title,
.instructor-dashboard_content_favorite_flashcards_title,
.instructor-dashboard_content_favorite_quizzes_title {
    /* color: #0B4475; */
    text-decoration: underline;
    font-size: 20px;
}

.instructor-dashboard_content_total_students > span,
.instructor-dashboard_content_total_courses > span {
    color: #0B4475;
    font-size: 20px;
}

.instructor-dashboard_content_favorite_flashcards > button,
.instructor-dashboard_content_favorite_quizzes > button {
    background-color: #0B4475;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
}

.instructor-dashboard_content_favorite_flashcards > button:hover,
.instructor-dashboard_content_favorite_quizzes > button:hover {
    background-color: #1580DC;
}