.courseContent {
    width: 100%;
    height: 100%;
    /* max-height: 90vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.courseContente__box {
    width: 100%;
    transition: transform 150ms linear;
}

.courseContent__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* height: 32px; */
    padding: 0 12px;
    border-bottom: 1px solid #d1d7dc;
    height: 56px;
    cursor: pointer;
    background-color: #f7f9fa;
    transition: all 0.2s ease-in-out;
}

.courseContent__item:active {
    border-bottom: none;
}

.courseContent__item--left {
    display: flex;
    align-items: center;
    justify-content: center;
}

.courseContent__item--left img {
    width: auto; /* Maintain aspect ratio */
    margin-right: 12px; /* Space between image and text */
}

.courseContent__item--left h1 {
    font-size: var(--font-size-13);
    font-weight: 600;
    color: #0e0e0e;
    /* line-height: 0 !important; */
}

.courseContent__item--right {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.courseContent__item--right h2 {
    font-size: var(--font-size-12);
    line-height: 0%;
}

.arrow-down {
    transform: rotate(90deg);
    transition: transform 0.3s ease;
}

.courseContent__task {
    /* border: 1px solid red; */
    width: 100%;
}

.courseContent__task--content ul {
    margin-bottom: 0;
    border-bottom: 1px solid #d1d7dc;
}

.courseContent__task--content li {
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;

    padding: 0 12px;

    height: 56px;
}

.courseContent__task--content li:hover {
    background-color: var(--sidebar-task-bg); /* Light gray background on hover */
    cursor: pointer; /* Change cursor to pointer */
}

.courseContent__task--content li.active {
    background-color: #cce5ff; /* Light blue background for active task */
    border-left: 4px solid #007bff; /* Blue left border for active task */
}

.courseContent__task--topic {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    /* width: 100%; */
    height: 100%;
}

.courseContent__task--content li.active {
    background-color: #cce5ff; /* Light blue background for active task */
    border-left: 4px solid #007bff; /* Blue left border for active task */
}

.courseContent__task--check {
    position: relative; /* Set the container to relative positioning */

    display: flex;
    align-items: center;
    justify-content: center;
}

.courseContent__task--check img {
    position: absolute; /* Set images to absolute positioning */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust to center the images */
}

.courseContent__task-title {
    font-size: var(--font-size-12);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.courseContent__task-check-icon {
    width: 16px;
    height: 16px;
    /* border-radius: 0; */
    outline: none;
    border-radius: 20px !important; /* Rounded corners for the input field */
}
