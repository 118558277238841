.sidebar {
    height: 100%;

    border: 1px solid var(--dark-gray-20);
    box-sizing: border-box; /* Include border in the element's total width and height */
    background: #fff;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.sidebar__menu {
    width: 100%;
    border-bottom: 1px solid #d1d7dc;
    padding: 14px 16px;
}

.sidebar__menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
}

.sidebar__content {
    overflow-y: auto;
    height: 100%;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.sidebar__icon {
    width: 24px;
    height: 24px;
}

.sidebar__item {
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Vertically center items */
    justify-content: center; /* Horizontally center items */

    cursor: pointer; /* Change cursor to pointer on hover */
    transition: all 0.3s; /* Smooth background transition */

    width: auto;
}

.sidebar__item:hover {
    background-color: #f0f0f0; /* Change background on hover */
}

.sidebar__item.inactive {
    opacity: 0.8;
}

.sidebar__item span {
    font-size: var(--font-size-14);
    font-weight: 500;
    margin-left: 2px;
}

.sidebar__item span.inactive {
    display: none;
}

.sidebar__item:hover {
    opacity: 1;
}

.sidebar__icon-container {
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Vertically center icons */
    justify-content: center; /* Horizontally center icons */
    margin-right: 2px; /* Space between icons and text */
}

.sidebar__icon--aiTag {
    margin-left: 2px; /* Space between icons */
    width: 26px;
}

.sidebar__item:hover {
    background-color: #f0f0f0;
}

@media screen and (max-width: 1080px) {
    .sidebar__content {
        /* max-height: 280px; */
        height: 100%;
    }
}

@media (max-width: 345px) {
    .sidebar__menu span {
        font-size: var(--font-size-12);
    }
}
@media (max-width: 330px) {
    .sidebar__menu span {
        display: none;
    }
}
