.course_thumbnail {
    margin-bottom: 15px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
}

.course_box_title {
    font-size: 20px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
    margin-bottom: 0;
    /* height: 45px; */
}

.course_box_subtitle {
    font-size: 14px;
    margin-bottom: 15px;
    font-weight: 600;
    line-height: 1;
    margin-top: 10px;
}

.cp_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-weight: 700;
}

.price_discount {
    color: #ff0007;
    margin-right: 10px;
}

.price_strike {
    color: #808080;
    text-decoration: line-through;
    margin-right: 10px;
}

.price_actual {
    color: #0b4475;
}

.price_percentage {
    color: #808080;
}

.courses_btn {
    display: flex;
    gap: 20px;
}

.course_btns .btn_primary {
    margin-right: 10px;
    border-width: 2px;
}

.course_btns .btn_secondary {
    margin-right: 10px;
    border-width: 2px;
}

.course_btns .big_btn {
    padding: 12px 18px;
}

.filter_wrapper {
    display: flex;
    align-items: start;
    justify-content: center;
    position: relative;
}

.filter_btn {
    margin: 0 10px 10px 0px;
}

.sort_wrapper {
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
}

.sort_wrapper figure {
    width: 14px;
}

.sort_wrapper .form-control {
    background-color: #fff;
    border-radius: 0;
    padding: 0 5px;
}

.course_top {
    background-color: #d6ecff;
}

.course_inner {
    display: flex;
    align-items: flex-start;
}

.course_intro {
    width: 70%;
    margin-left: auto;
    padding-left: 100px;
}

.course_title {
    font-size: 30px;
}

.course_subtitle {
    font-size: 20px;
    color: #0b4475;
    font-weight: 600;
}

.course_description {
    font-size: 18px;
    font-weight: 600;
}

.course_stat {
    padding-left: 0;
    margin-bottom: 0;
}

.course_stat li {
    display: inline-flex;
    margin-right: 20px;
    font-size: 13px;
    align-items: center;
}

.course_stat figure {
    margin-right: 5px;
    width: 20px;
}

.course_stat span {
    line-height: 1;
}

.course_details {
    display: flex;
    align-items: flex-start;
    position: relative;
}

.course_detail_box {
    width: 30%;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #fff;
    margin-top: -385px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

/* .course_video_thumb {
    position: relative;
    cursor: pointer;
} */

.play_button {
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%, -50%);
    width: 60px;
}

@media (max-width: 768px) {
    .play_button {
        top: 5%;
    }
}

.course_details .course_stat {
    margin-bottom: 20px;
}

.course_details .course_stat li {
    width: 100%;
    margin: 0 0 10px;
}

.course_details .course_stat figure {
    margin-right: 10px;
}

.course_details .social_media button {
    margin-right: 8px;
}

.course_details .social_media a {
    margin-left: 0;
    margin-right: 10px;
}

.course_detail_box h6 {
    font-size: 14px;
}

.course_detail_box .price_discount {
    font-size: 18px;
}

.course_detail_box .price_strike,
.course_detail_box .price_percentage {
    font-size: 14px;
}

.course_tabs {
    width: 70%;
}

.course_tabs .nav-tabs {
    border-bottom: none;
}

.course_tabs .nav-tabs .nav-link {
    border: none;
    border-radius: 10px;
    color: #808080;
    font-weight: 600;
    background-color: #eee;
    margin-right: 20px;
    padding: 10px 20px;
}

.course_tabs .nav-tabs .nav-item.show .nav-link,
.course_tabs .nav-tabs .nav-link.active {
    color: #fff;
    background-color: #0b4475;
}

.course_tabs h3 {
    font-size: 20px;
}

.circle_tick_list {
    padding-left: 0;
    list-style: none;
}

.circle_tick_list li {
    margin-bottom: 15px;
    padding-left: 40px;
    background: url(../image/icons/icon_circle_tick.svg) center left no-repeat;
    background-size: 20px;
    font-weight: 600;
}

.curriculum_header {
    display: flex;
    align-items: center;
    padding: 0 20px 5px 20px;
    margin-bottom: 10px;
}

.curriculum_header span {
    font-weight: 600;
    font-size: 13px;
}

.curriculum_row {
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: #eee;
    border-radius: 10px;
    cursor: pointer;
}

.curriculum_row:hover,
.curriculum_row.active {
    background-color: #d6ecff;
}

.curriculum_checkbox {
    margin-right: 10px;
    line-height: 1;
}

.curriculum_checkbox .form-check-input {
    position: static;
}

.curriculum_title {
    flex: 1;
    padding: 0 15px;
}

.curriculum_title h2 {
    font-weight: 600;
    font-size: 16px;
}

.curriculum_title span {
    font-size: 13px;
}

.curriculum_title span figure {
    display: inline-block;
    width: 16px;
}

.curriculum_thumbnail {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    width: 100px;
}

.curriculum_thumbnail .play_button {
    width: 30px;
}

.instructor {
    background-color: #d6ecff;
}

.instructor_detail {
    display: flex;
    align-items: flex-start;
}

.instructor_photo {
    width: 150px;
    border-radius: 10px;
    overflow: hidden;
}

.instructor_info {
    width: calc(100% - 150px);
    padding-left: 30px;
}

.instructor_info h2 {
    font-size: 20px;
    font-weight: 600;
}

.instructor_info li {
    font-weight: 600;
}

.instructor p {
    font-size: 14px;
    font-weight: 600;
}

.continue_swiper .swiper-wrapper {
    padding: 10px 0;
}

.continue_main_box {
    box-shadow: 3px 5px 12px #0000003d;
    padding: 20px;
    border-radius: 10px;
}

.continue_course {
    display: flex;
    align-items: flex-start;
}

.continue_course .course_thumbnail {
    width: 40%;
    margin-bottom: 0;
}

.continue_title_box {
    width: 60%;
    padding-left: 15px;
}

.continue_title_box .course_box_title {
    min-height: auto;
}

.continue_title_box .course_box_subtitle {
    margin-bottom: 0;
}

.cc_bar {
    margin-top: 20px;
}

.cc_bar span {
    font-size: 14px;
    color: #808080;
}

.continue_track {
    position: relative;
    width: 100%;
    height: 8px;
    border-radius: 10px;
    background-color: #ccc;
}

.continue_bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    background-color: #009a00;
    border-radius: 10px;
}

.full_course {
    display: flex;
    align-items: stretch;
    border-radius: 10px;
    border: 1px solid #707070;
    padding: 30px;
}

.full_course .course_thumbnail {
    width: 40%;
    margin-bottom: 0;
}

.full_course .full_course_info {
    width: 60%;
    padding-left: 50px;
}

.full_course .course_box_title {
    font-size: 34px;
    font-weight: 800;
    min-height: auto;
}

.full_course .course_box_subtitle {
    font-size: 18px;
    font-weight: 600;
}

.full_course p {
    font-size: 14px;
    font-weight: 600;
}

.full_course .cp_wrapper {
    font-weight: 800;
    font-size: 20px;
    margin-top: 30px;
}

.full_course .course_btns {
    margin-top: 30px;
}

.course_wrapper {
    display: flex;
    align-items: flex-start;
    /* flex-wrap: wrap; */
    margin: 0 -15px;
    position: relative;
    /* min-height: 800px; */
}

/* .course_video {
    width: calc(65% - 30px);
    order: 1;
    margin: 0 15px;
    border: 1px solid #cfcfcf;
} */

/* .course_video canvas {
    width: 100% !important;
    height: auto !important;
} */

.course_topic {
    width: calc(35% - 30px);
    order: 2;
    margin: 0 15px;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #f6f6f6;
    max-height: 600px;
    /* Adjust this value as needed */
    overflow-y: auto;
}

/* .course_overview {
    width: calc(65% - 30px);
    order: 3;
    margin: 0 15px;
} */

.course_overview .nav-tabs {
    display: flex;
    align-items: stretch;
}

.course_overview .nav-tabs .nav-item {
    width: 50%;
}

.course_overview .nav-tabs .nav-link {
    text-align: center;
    border: none;
    border-bottom: 3px solid #fff;
    color: #808080;
    font-weight: 800;
    font-size: 20px;
}

.course_overview .nav-tabs .nav-link.active {
    border-color: #000;
    color: #000;
}

.topic_name {
    font-size: 18px;
    margin-bottom: 10px;
}

.topic_box {
    box-shadow: 0 3px 6px #00000029;
    border-radius: 10px;
}

.topic_box h3 {
    font-size: 20px;
}

.topic_row {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
}

.topic_row:hover,
.active_chapter {
    background-color: rgba(30, 151, 255, 0.2);
}

.topic_row .form-check-input {
    width: 20px;
    position: relative;
    border-color: #707070;
}

.topic_info {
    flex: 1;
    min-width: 1px;
    width: 100%;
    padding-left: 15px;
}

.topic_info h6 {
    font-size: 16px;
    margin-bottom: 0;
}

.topic_time {
    display: flex;
    align-items: center;
}

.topic_time figure {
    width: 16px;
    display: inline-block;
    margin-right: 5px;
}

.topic_time svg {
    width: 100%;
    height: auto;
}

.topic_row:hover .topic_time_icon {
    fill: #1e97ff;
}

.topic_time small {
    line-height: 1;
    font-size: 14px;
    font-weight: 600;
    color: #808080;
}

.topic_chapter {
    background-color: #ddd;
    padding: 10px 30px;
    font-size: 14px;
    font-weight: 600;
}

.quiz_box {
    width: calc(65% - 30px);
    order: 1;
    margin: 0 15px;
}

.quiz_count {
    color: #888;
    margin-bottom: 15px;
    display: inline-block;
}

.quiz_question {
    margin-bottom: 10px;
}

.quiz_box small {
    color: #888;
    display: inline-block;
    width: 100%;
    /* margin-bottom: 15px; */
    font-size: 16px;
}

.quiz_options {
    display: inline-block;
    width: 100%;
    margin-bottom: 15px;
}

.quiz_options label {
    display: inline-block;
    width: 100%;
    font-size: 13px;
    margin: 10px 0;
    font-weight: 400;
    border: 1px solid #ddd;
    /* border-radius: 5px; */
    position: relative;
}

.quiz_options label.selected {
    border-color: #0b4475;
}

.quiz_options input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.option_answer {
    display: flex;
    align-items: stretch;
    width: 100%;
}

.option_number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    /* border-radius: 5px; */
    background-color: #ddd;
}

.selected .option_number {
    background-color: #0b4475;
    color: #fff;
}

.selected .option_answer .incorrect_answer {
    background-color: #ff0007;
    color: #fff;
}

.selected .option_answer .correct_answer {
    background-color: #3a9200;
    color: #fff;
}

.correct_answer_border {
    border-color: #3a9200 !important;
}

.incorrect_answer_border {
    border-color: #ff0007 !important;
}

.option_answer span {
    padding: 10px;
    padding-right: 30px;
    flex: 1;
}

.quiz_indicator {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    display: none;
}

.quiz_answer {
    background-color: #ddd;
    font-weight: 500;
    padding: 10px 20px;
    /* border-radius: 5px; */
    font-size: 13px;
    margin-bottom: 15px;
}

.correct {
    color: #009a00;
}

.correct_rtl {
    color: #009a00;
    float: right;
}

.incorrect {
    color: #ff0007;
}

.incorrect_rtl {
    color: #ff0007;
    float: right;
}

.bold_rtl {
    float: right;
}

.quiz_result {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.quiz_result p {
    margin-bottom: 15px;
    font-weight: 500;
}

.quiz_result span {
    font-size: 50px;
    color: #009a00;
    line-height: 1;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .course_detail_box {
        width: 35%;
    }

    .course_intro,
    .course_tabs.pl_100 {
        width: 65%;
        padding-left: 50px;
    }

    .course_intro {
        margin-left: 350px;
    }

    .course_tabs.pl_100 {
        margin-left: 35px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .cp_wrapper {
        font-size: 14px;
    }

    .course_btns .big_btn {
        padding: 10px 15px;
    }

    .course_detail_box.p_30 {
        width: 35%;
        padding: 20px;
        margin-top: -355px;
    }

    .course_detail_box .cp_wrapper {
        font-size: 12px;
    }

    .course_intro,
    .course_tabs.pl_100 {
        width: 65%;
        padding-left: 50px;
    }

    .course_tabs .nav-tabs .nav-link {
        font-size: 14px;
        padding: 7px 15px;
    }

    .course_tabs p,
    .circle_tick_list li {
        font-size: 14px;
    }

    .curriculum_row {
        padding: 20px;
    }
}

@media screen and (max-width: 991px) {
    .course_box_title {
        font-size: 14px;
        height: 40px;
    }

    .course_wrapper {
        margin: 0;
        min-height: auto;
    }

    /* .course_video {
        width: 100%;
        margin: 0;
    } */

    .course_topic {
        position: static;
        width: 100%;
        margin: 20px 0;
        overflow: auto;
        background-color: #f6f6f6;
        max-height: 600px;
        /* Adjust this value as needed */
        overflow-y: auto;
    }

    /* .course_overview {
        width: 100%;
        margin: 0;
    } */

    .quiz_box {
        width: 100%;
        margin: 0;
    }
}

@media screen and (max-width: 767px) {
    .course_btns.mt_30 {
        margin-top: 15px;
    }

    .course_btns .big_btn {
        padding: 7px 10px;
    }

    .course_box_title {
        height: auto;
        overflow: visible;
    }

    .course_intro {
        width: 100%;
        padding-left: 0;
    }

    .course_details {
        flex-wrap: wrap;
    }

    .course_detail_box.p_30 {
        width: 100%;
        margin-top: 0;
        box-shadow: none;
        padding: 0;
        position: static;
    }

    .course_title {
        font-size: 24px;
    }

    .course_subtitle {
        font-size: 16px;
    }

    .course_description {
        font-size: 14px;
        line-height: 1.4;
        margin-bottom: 10px;
    }

    .course_stat li {
        width: 100%;
        margin-right: 0;
        margin: 5px 0;
    }

    .course_details .course_stat {
        margin-bottom: 10px;
    }

    .course_tabs.pl_100 {
        width: 100%;
        padding-left: 0;
        margin-top: 20px;
    }

    .course_tabs .nav-tabs {
        display: none;
    }

    .course_tabs .fade:not(.show) {
        opacity: 1;
    }

    .course_tabs .tab-content > .tab-pane {
        display: block;
    }

    .curriculum_row {
        flex-wrap: wrap;
        align-items: flex-start;
    }

    .curriculum_row .btn_primary {
        margin-top: 15px;
        width: 100%;
    }

    .curriculum_thumbnail {
        width: 70px;
    }

    .curriculum_thumbnail .play_button {
        width: 20px;
    }

    .curriculum_title h2 {
        font-size: 13px;
    }

    .instructor.p_30 {
        padding: 15px;
    }

    .instructor_detail.mb_30 {
        margin-bottom: 10px;
    }

    .instructor_photo {
        width: 100px;
    }

    .instructor_info {
        width: calc(100% - 100px);
        padding-left: 20px;
    }

    .instructor_info h2.mb_20 {
        margin-bottom: 10px;
        font-size: 16px;
    }

    .course_details .course_stat li {
        margin-bottom: 5px;
    }

    .course_details .course_stat li:last-child {
        margin-bottom: 0;
    }

    .course_tabs h3 {
        font-size: 16px;
    }

    .all_filters {
        text-align: center;
        width: 100%;
    }

    .filter_btn.btn_primary_inverted {
        font-size: 13px;
    }

    .sort_wrapper .col-12 {
        max-width: 50%;
        /* Ensure each select box takes 50% of the container's width */
        flex: 0 0 50%;
    }

    .sort_wrapper {
        position: static;
        width: 400px;
        margin: 20px 0 0;
    }

    .full_course {
        flex-wrap: wrap;
        padding: 20px;
    }

    .full_course .course_thumbnail {
        width: 100%;
        margin-bottom: 20px;
    }

    .full_course .full_course_info {
        width: 100%;
        padding: 0;
    }

    .full_course .course_box_title {
        font-size: 24px;
    }

    .full_course .cp_wrapper {
        font-size: 16px;
        margin-top: 10px;
    }

    .full_course .course_btns {
        margin-top: 10px;
    }

    .quiz_options label {
        margin: 5px 0;
    }

    .quiz_result span {
        font-size: 40px;
    }

    .topic_row.plr_30,
    .topic_chapter {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.accordion-item-panel {
    margin: 0px;
}

@media (max-width: 563px) {
    .nav-link {
        white-space: wrap !important;
    }
}

#myTab {
    flex-wrap: nowrap;
}

@media (max-width: 485px) {
    #myTab {
        flex-wrap: wrap;
    }
}

@media (max-width: 404px) {
    #courseContent-tab,
    #ace-tab,
    #Overview-tab,
    #connect-tab {
        width: 120px !important;
    }
}

@media (max-width: 1154px) {
    .course_box_title {
        font-size: 14px;
        height: 40px;
    }

    .course_wrapper {
        margin: 0;
        min-height: auto;
        flex-wrap: wrap;
        flex-direction: column-reverse !important;
    }

    /* .course_video {
        width: 100%;
        margin: 0;
    } */

    .course_topic {
        position: static;
        width: 100%;
        margin: 20px 0;
        overflow: auto;
        background-color: #f6f6f6;
        max-height: 600px;
        /* Adjust this value as needed */
        overflow-y: auto;
    }

    /* .course_overview {
        width: 100%;
        margin: 0;
    } */

    /* .quiz_box {
        width: 100%;
        margin: 0;
    } */
}
