.favorites-container {
    height: 780px;
    width: 100%;
}

.favorites-container .ace__switch-menu {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.favorites-quizzes {
    overflow-y: scroll;
}

.favorites-flashcards,
.favorites-quizzes {
    height: 93.8%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.favorites-flashcards-empty,
.favorites-quizzes-empty {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.favorites-flashcards-empty h2,
.favorites-quizzes-empty h2 {
    color: #0B4374;
}

.favorites-flashcards .flashcard {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

.favorites-flashcards-actions,
.favorites-quizzes-item-actions {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 20px;
}

.favorites-flashcards-actions button,
.favorites-quizzes-item-actions button {
    width: 100px;
    height: 50px;
    border-radius: 5px;
    background-color: #0B4374;
    color: #fff;
    cursor: pointer;
    border: none;
    transition: 0.3s;
}

.favorites-flashcards-actions button:hover {
    background-color: #1580DC;
    color: #fff;
    transition: 0.3s;
}

.my-swal-popup {
    width: 800px; /* or whatever width you prefer */
    overflow-x: hidden;
}

.my-swal-content {
    /* Add any content-specific styles */
    overflow-x: hidden;
}

.my-swal-container {
    /* Add any container-specific styles */
    overflow-x: hidden;
}

#swal-feedback {
    width: 80%;
    height: 200px;
}

#swal-rating {
    width: 40% !important;
}

#swal2-html-container {
    overflow-x: hidden;
}

.favorites-quizzes-item {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    border: 1px solid #0B4374;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.favorites-quizzes-item-question {
    font-size: 20px;
    font-weight: bold;
    color: #0B4374;
}

.favorites-quizzes-item-answer {
    font-size: 16px;
    color: limegreen;
}

.favorites-quizzes-item-incorrect-answer {
    font-size: 16px;
}

.favorites-quizzes-item-explanation {
    font-size: 16px;
    color: #0B4374;
    font-weight: bold;
}

.favorites-quizzes-item-actions button:hover {
    background-color: #1580DC;
    color: #fff;
    transition: 0.3s;
}

.favorites-quizzes-item-actions {
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 353px) {
    .favorites-quizzes-item-actions {
        flex-direction: column;
        gap: 10px;
    }
}

@media (max-width: 618px) {
    #favorites-switch-menu {
        width: 100%;
    }
}