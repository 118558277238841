.connect {
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
    padding: 20px;
}

/* .connect__user-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.connect__user-info-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
}

.connect__user-info-content-title {
    font-size: var(--font-size-16);
    font-weight: 500;
    text-decoration: underline;
    color: var(--accent);
}

.connect__user-info-content-date {
    font-size: var(--font-size-12);
    color: var(--dark-gray-40);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.connect__user-request-topic {
    font-size: var(--font-size-16);
    line-height: 20px;
    font-weight: 600;
    margin-top: 12px;
}

.connect__user-request-content {
    font-size: var(--font-size-14);
    line-height: 20px;
    color: var(--dark-gray-70);
    font-weight: 600;
    margin-top: 8px;
}

.connect__select_instructor {
    width: 100%;
    height: 40px;
    border: 1px solid var(--primary-60);
    border-radius: 4px;
}

.connect__QA-tutor {
    display: flex;
    align-items: center;
    gap: 4px;
}

.connect__QA-tutor-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: var(--secondary);
    font-size: var(--font-size-14);
    font-weight: 600;
}

.connect__QA-reply {
    color: var(--secondary);
    font-size: var(--font-size-14);
    font-weight: 600;
    margin-top: 8px;
}

.connect__QA-ask {
    color: var(--dark-gray);
    font-size: var(--font-size-14);
    font-weight: 600;
    margin-top: 8px;
} */

/* .connect__ask_question {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
} */

.connect__title {
    font-size: 24px;
    font-weight: 800;
    color: var(--primary);
    margin-bottom: 20px;
}

/* .connect__ask_question-form-instructor {
    margin-bottom: 12px;

    position: relative;
    display: inline-block;
    width: 100%;
} */

.connect__instructors {
    border-radius: 4px;
    padding: 12px;
}

.connect__instructor-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;

    border: 1px solid var(--primary-60);
    margin-bottom: 12px;
    padding: 14px;
    border-radius: 24px;
}

/* .connect__instructor-card-left {
    display: flex;

    align-items: center;
    gap: 8px;
} */

.connect__instructor-profile {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: var(--secondary);
    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
}

.connect__instructor-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* This ensures the image covers the entire circle */
}

.connect__instructor-initials {
    font-size: 20px;
    font-weight: 600;
    color: var(--white);

    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.connect__instructor-name {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 700;
    color: var(--primary);
}

.connect__ask-button {
    background-color: var(--light-blue);
    width: 100%;
    color: var(--white);
    padding: 8px 12px;
    border-radius: 40px;
    font-size: 14px;
    font-weight: 600;
    border: none;
}

/* .connect__ask_question-form-instructor select {
    width: 100%;
    padding: 8px 12px;
    height: 40px;
    border: 1px solid var(--primary-60);

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuNzA3MTQgNC4yOTI4N0w4IDExLjU4NTZMMTQuMjkyOCA0LjI5Mjg3IiBzdHJva2U9IiMwQjQzNzQiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K')
        no-repeat;
    background-position: calc(100% - 24px) center;
    background-size: 12px;
} */

/* .connect__ask_question-form-instructor select:focus {
    width: 100%;
    padding: 8px 12px;
    height: 40px !important;
    border: 1px solid var(--primary-60);
    outline: none;
} */

/* .connect__ask_question-form-input input {
    width: 100%;
    height: 40px;
    border: 1px solid var(--primary-60);
    padding: 0 12px;
} */

/* .connect__ask_question-form-input input:focus {
    border-color: #0b4374;
    outline: none;
}

.connect__ask_question-form-btns button {
    border-radius: 0;
} */
