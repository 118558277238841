.address_box {
    background-color: #fff;
    box-shadow: 0 3px 6px #00000029;
    padding: 20px 20px;
}
.category {
    color: #0b4475;
    font-size: 22px;
    font-weight: 800;
    margin-bottom: 0;
    overflow: hidden;
}
