.instructor-bg {
    width: 100%;
    height: 140px;
    background-color: #0b4374;
}
.instructor-bg2 {
    box-shadow: 0 3px 6px #00000029;
    background-color: #fff;
    width: 100%;
}
.inst-wrapper {
    display: flex;
    align-items: flex-end;
    position: relative;
}
.inst-imgbg {
    width: 130px;
    height: 130px;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    position: absolute;
    bottom: 10px;
    left: 0;
}
.instructor-name {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    margin-left: 150px;
    padding: 20px 0;
}
.dashboard {
    display: flex;
    align-items: center;
    margin: 30px 8px;
}
.dashboard-img {
    width: 30px;
    height: 30px;
}
.dashboard-text {
    color: #0b4475;
    font-size: 20px;
    font-weight: 600;
}
.card1-main {
    display: flex;
    align-items: center;
}
.card1 {
    box-shadow: 0 3px 6px #00000029;
    background-color: #eee;
    color: #000000;
    border-radius: 8px;
    width: calc(50% - 20px);
    margin: 0 8px;
    height: 200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.card1:hover {
    color: #000000;
}
.card1-text {
    font-size: 16px;
    font-weight: 600;
}
.card1-icon {
    width: 50px;
    height: 50px;
}
.card1-value {
    font-size: 16px;
    font-weight: 600;
}
.card2-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.card2 {
    box-shadow: 0 3px 6px #00000029;
    background-color: #eee;
    border-radius: 8px;
    width: calc(50% - 20px);
    margin: 30px 8px;
    height: 150px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
}
.card2-icon {
    width: 50px;
}
.card2-upArrow {
    width: 10px;
    height: 12px;
}
.card2-text {
    font-size: 14px;
}
.card2-link {
    color: #1e97ff;
    font-size: 14px;
    cursor: pointer;
}
.card2-link:hover {
    color: #166ab2;
}
.card2-perc {
    color: #3a9200;
    font-size: 14px;
}
.card2-value {
    font-weight: 700;
}
.card2-detail {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    text-align: start;
}
.detail-wrap {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.table-main {
    background-color: #eee;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 8px;
    padding: 10px 0 20px;
    margin: 0 8px;
}
.table-headText {
    padding: 12px 0 12px 40px;
    border-bottom: 1px solid #fff;
    font-size: 18px;
    font-weight: 700;
}
.table-main table {
    position: relative;
    left: 30px;
    width: 100%;
}
.table-main table td {
    font-size: 14px;
}
.btn-bg {
    text-align: end;
    margin-right: 50px;
}
.view-btn {
    padding: 15px 60px;
    background-color: #0b4475;
    border: none;
    border-radius: 8px;
    color: #fff;
}
.view-btn:focus {
    outline: none;
}
.view-btn:hover {
    color: #fff;
}
.recent-detail {
    background-color: #eee;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 8px;
    padding: 10px 0 20px;
    margin: 0 8px;
}
.recent-main {
    display: flex;
    align-items: center;
    padding: 30px 0 30px 30px;
}
.recent-img {
    width: 140px;
    height: 70px;
    border-radius: 8px;
}

.instructor_box {
    background-color: #fff;
    box-shadow: 0 3px 6px #00000029;
    padding: 30px 20px;
    text-align: center;
    margin-bottom: 20px;
}
.instructor_profile {
    margin: 0 auto 20px;
    width: 140px;
    height: 140px;
    font-weight: 800;
    font-size: 40px;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #1392be;
    color: #fff;
    text-transform: uppercase;
    overflow: hidden;
}
.instructor_box_name {
    font-size: 22px;
    font-weight: 800;
    margin-bottom: 0;
    height: 70px;
    overflow: hidden;
}
.instructor_box_profile {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px;
}
.instructor_btn {
    background-color: #1e97ff;
    color: #fff;
    font-size: 13px;
    border-radius: 30px;
    padding: 15px 20px;
    font-weight: 600;
}
.instructor_btn:hover {
    color: #fff;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 991px) {
    .btn-bg {
        margin-right: 20px;
    }
    .view-btn {
        padding: 10px 30px;
    }
    .table-main table td {
        white-space: nowrap;
    }
    .instructor_btn {
        padding: 10px 20px;
    }
    .instructor_box_name {
        height: auto;
    }
}
@media screen and (max-width: 767px) {
    .card1-main {
        flex-wrap: wrap;
    }
    .card1 {
        width: 100%;
        margin-bottom: 20px;
    }
    .inst-imgbg {
        width: 70px;
        height: 70px;
    }
    .instructor-name {
        font-size: 14px;
        margin-left: 90px;
        padding: 10px 0px;
    }
    .card2-main {
        flex-wrap: wrap;
    }
    .card2 {
        width: 100%;
        margin: 0px 8px 20px 8px;
    }
    .table-headText {
        padding: 12px 0px 12px 20px;
    }
    .table-main table {
        left: 0px;
        width: 100%;
    }
    .instructor-bg {
        height: 70px;
    }
    .recent-main {
        padding: 20px 0px 20px 6px;
    }
    .recent-text {
        margin-left: 20px;
    }
    .dashboard-text {
        font-size: 14px;
    }
    .instructor_profile {
        width: 100px;
        height: 100px;
        font-size: 30px;
    }
}
/*Bilal*/
.rounded-circle {
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1995bf;
    color: #ffffff;
    line-height: 2.5;
    text-align: center;
    font-size: 30px;
    margin-right: 40px;
    box-shadow: 0px 3px 6px #00000029;
}
.grey {
    background-color: #eeeeee;
    color: #000000;
    padding: 15px;
    border-radius: 10px;
    font-size: 16px;
    margin-top: 18px;
    font-weight: 600;
}
.blue {
    background-color: #1e97ff;
    border-radius: 10px;
    color: #ffffff;
    font-size: 16px;
    padding: 15px;
    margin-top: 30px;
}
.grey_input {
    background-color: #eeeeee !important;
    padding: 10px;
    margin-top: 80px;
    display: flex;
}
.grey_input input {
    border-radius: 24px;
}
.form_ccontrol {
    display: block;
    width: 100%;
    height: calc(1.2em + 0.6rem + 2px);
    padding: 0.375rem 0.85rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2;
    color: black;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    outline: none;
    border-radius: 0.25rem;
    transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

.svg_icon {
    margin-top: 5px;
}

.hding_ttle {
    padding: 0;
    margin: 0;
    font-size: 12px;
}

.chat_instor_ttle {
    font-size: 25px;
    font-weight: 600;
    color: #000000;
}

.hding_ttle {
    color: #000000;
    font-size: 12px;
    font-weight: 500;
}

.chat_student_hd_a {
    color: #000000;
    font-size: 12px;
    font-weight: 500;
}

.blue_chat_box_ttle {
    font-size: 14px;
    color: #ffffff;
    font-weight: 300;
}

.form_ccontrol::placeholder {
    font-size: 12px;
}

.bx_name {
    font-weight: 600;
    font-size: 40px;
}

.dte_component {
    color: #808080;
    font-size: 13px;
}

/*chat*/
.message_section {
    margin: 50px 0 0;
    display: inline-block;
    width: 100%;
}
.messages {
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
}
.msg_header {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #ccc;
}
.msg_profile {
    display: inline-flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 100%;
    background-color: #1392be;
    color: #fff;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 800;
}
.msg_profile_details {
    margin-left: 20px;
    flex: 1;
}
.msg_name {
    font-size: 16px;
    font-weight: 800;
}
.msg_designation {
    font-size: 12px;
    font-weight: 600;
}
.msg_win {
    padding: 0 20px 20px;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 325px);
    margin: 10px 0;
}
.empty-chat {
    height: calc(100vh - 325px);
}
.msg_row {
    margin-bottom: 10px;
    max-width: 60%;
}
.msg_row:last-child {
    margin-bottom: 0;
}
.msg_text,
.msg_file {
    border-radius: 5px;
    overflow: hidden;
    padding: 15px 10px;
    font-size: 13px;
    font-weight: 500;
}
.msg_text p:last-child,
.msg_file p:last-child {
    margin-bottom: 0;
}
.msg_sender {
    margin-left: auto;
}
.msg_sender .msg_text,
.msg_sender .msg_file {
    color: #fff;
    background-color: #1e97ff;
}
.msg_receiver .msg_text,
.msg_receiver .msg_file {
    color: #000;
    background-color: #eee;
    border: 1px solid #ddd;
}
.msg_date {
    font-size: 13px;
    font-weight: 300;
    color: #aaa;
    float: right;
}
.msg_footer {
    background-color: #eee;
    padding: 10px 15px;
    border-top: 1px solid #ccc;
}
.msg_input {
    display: flex;
    align-items: center;
}
.msg_input .form-control {
    border-radius: 30px;
    background-color: #fff;
    color: #000;
    height: auto;
    padding: 8px 20px;
}
.msg_sent {
    outline: none;
    border: none;
}
.form_below_notes {
    font-size: 0.8rem;
    font-family: 'Metropolis', sans-serif;
    font-weight: 500;
    color: #8a9197;
}
.download_button {
    width: auto;
}
.download_button img {
    width: 20px;
    margin: 0px 10px;
}
.login-text {
    padding-left: '10px';
    color: #007bff !important;
    text-decoration: none;
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
    .message_section {
        margin: 0;
    }
    .msg_header {
        padding: 10px 15px;
    }
    .msg_win {
        height: calc(100vh - 300px);
    }
    .msg_row {
        max-width: 70%;
    }
}
