button:active {
    opacity: 0.9;
    border: none;
}

button:focus {
    outline: none;
}

.menu-open-icon {
    display: none;
}

.course-page {
    display: flex;

    justify-content: center; /* Align items to the start */
    align-items: stretch;
    position: relative;
    height: 100%;
    gap: 14px;
    padding: 32px 20px 60px 20px;
}

.course-page__sidebar {
    flex: 0 0 33.33%;
    overflow: hidden;
    height: auto;
}

.course-page__content {
    flex: 2; /* Take 2/3 of the width */
    width: 100%;
    /* height: 100%; */
    height: 750px;
    min-height: 100px;

    overflow: auto;
    border: 1px solid var(--dark-gray-20);

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.course__container {
}

.course_video {
    height: 780px;
    /* margin-top: 70px;
    margin-bottom: 70px; */
    /* padding-top: 4%; */
}

.error-message {
    color: var(--error);
    font-size: 20px;
    font-weight: 600;
    /* min-height: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 446px;
}

@media (max-width: 1349px) {
    .course-page {
        flex-direction: column-reverse;
    }

    .course_video {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}

@media (max-width: 1080px) {
    .course-page {
        height: auto; /* Change from fixed 2300px */
        min-height: 100vh; /* Ensure it at least fills the viewport */
    }

    .course-page__content {
        height: auto; /* Allow content to determine height */
        min-height: 100px;
    }
}