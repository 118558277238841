/* Study Guide Dashboard */
.study-guide-dashboard {
    display: flex;
    justify-content: flex-start;
    /* min-height: 700px; */
    /* max-height: 700px; */
    height: 749px !important;
    width: 100%;
    flex-direction: row;
    position: relative;
    gap: 20px;
}

@media (max-width: 1024px) {
    .study-guide-dashboard {
        flex-direction: column;
        gap: 0;
    }

    .study-guide-dashboard_main_content {
        width: 100% !important;
        padding: 15px;
    }

    .study-guide-dashboard_sidebar {
        display: none;
    }

    .study-guide-dashboard_summary_report_stats {
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
    }

    .study-guide-dashboard-progress {
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
    }

    .progress_item {
        width: calc(50% - 20px);
        min-width: 200px;
    }

    .study-guide-dashboard_topics {
        height: auto;
        min-height: 180px;
    }

    .schedule_item {
        width: 95%;
        padding: 0 15px;
    }

    .schedule_item_details {
        flex: 1;
        min-width: 0;
    }

    .week-and-checkbox > span {
        width: auto;
        min-width: 70px;
        padding-right: 10px;
    }

    .checkbox {
        min-width: 14px;
        margin: 0 10px;
    }
}

@media (max-width: 768px) {
    .stat_item {
        width: calc(50% - 20px);
    }

    .progress_item {
        width: 100%;
    }

    .study-guide-dashboard_top_banner {
        padding: 15px;
    }

    .study-guide-dashboard_top_banner > h4 {
        font-size: 18px;
    }

    .study-guide-dashboard_top_banner > h5 {
        font-size: 14px;
    }

    .schedule_item {
        padding: 0 10px;
    }

    .schedule_item_details_task_name {
        width: auto;
        max-width: 150px;
    }

    .study-guide-dashboard_sidebar_schedule_header {
        width: 95%;
    }

    .study-guide-dashboard_topics {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
    }

    .study-guide-dashboard_topics > span {
        width: 100%;
        max-width: 300px;
        text-align: center;
    }

    .study-guide-dashboard_main_content > h4 {
        text-align: center;
    }
}

@media (max-width: 480px) {
    .stat_item {
        width: 100%;
    }

    .stat_item_outer_ring {
        width: 70px;
        height: 70px;
    }

    .stat_item_inner_ring {
        width: 50px;
        height: 50px;
    }

    .stat_item_inner_ring > span {
        font-size: 16px;
    }

    .week-and-checkbox > span {
        min-width: 60px;
        font-size: 12px;
    }

    .schedule_item {
        height: auto;
        min-height: 64px;
        padding: 10px;
    }

    .schedule_item_details {
        padding-left: 10px;
    }

    .schedule_item_details_task_name {
        width: 80px;
        font-size: 12px;
    }

    .schedule_item_details > span:last-of-type {
        font-size: 10px;
    }
}

@media (max-width: 601px) {
    .study-guide-dashboard_summary_report_stats {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .study-guide-dashboard-progress {
        flex-direction: column !important;
        align-items: center !important;
        gap: 20px !important;
    }

    .study-guide-dashboard-progress .progress_item {
        width: 100% !important;
        max-width: 300px !important;
    }

    .stat_item {
        width: 100%;
        max-width: 300px; /* Prevent items from being too wide */
    }

    .study-guide-dashboard_topics {
        width: 100%;
        align-items: center;
        text-align: center;
    }

    .study-guide-dashboard_topics > span {
        width: 100%;
        max-width: 300px;
    }

    .study-guide-dashboard_main_content > h4 {
        text-align: center;
    }

    .study-guide-dashboard_top_banner {
        text-align: center;
        padding: 15px;
    }
}

@media (max-width: 871px) and (min-width: 602px) {
    .study-guide-dashboard-progress {
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: nowrap !important;
        justify-content: center !important;
        gap: 20px !important;
    }

    .study-guide-dashboard-progress .progress_item {
        flex: 1 1 0 !important;
        min-width: 0 !important;
        max-width: 200px !important;
    }
}

/* Remove any conflicting progress item layouts from other media queries */
@media (max-width: 768px) {
    /* Keep other styles but remove any .study-guide-dashboard-progress styles */
}

.study-guide-dashboard_main_content {
    flex: 1;
    min-width: 0;
    padding: 20px;
}

.study-guide-dashboard_main_content > h4 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #0B4374;
}

.study-guide-dashboard_top_banner > h4 {
    font-size: 20px;
    font-weight: bold;
}

.study-guide-dashboard_top_banner > h5 {
    font-size: 16px;
}

/* Study Guide Dashboard Sidebar */
.study-guide-dashboard_top_banner {
    background: linear-gradient(96.83deg, #00A991 0%, #0B4475 100%);
    color: white;
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.study-guide-dashboard_sidebar_avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.study-guide-dashboard_sidebar_avatar_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.study-guide-dashboard_sidebar_avatar_container > h4 {
    font-size: 18px;
    font-weight: bold;
    color: #0B4374;
}

.study-guide-dashboard_sidebar {
    background-color: #EAF8FF;
    width: 330px;
    min-width: 330px;
    max-width: 330px;
    flex: 0 0 330px;
    flex-shrink: 0;
    position: sticky;
    top: 0;
    height: 100%;
    overflow-y: hidden;
}

.study-guide-dashboard_sidebar_date {
    font-size: 16px;
    font-weight: bold;
    margin: auto;
    width: fit-content;
    color: #1580DC;
}

.study-guide-dashboard_sidebar_date_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #1580DC;
}

.study-guide-dashboard_sidebar_schedule {
    height: calc(100% - 135px);
}

.study-guide-dashboard_sidebar_schedule_header {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    align-items: center;
}

.study-guide-dashboard_sidebar_schedule_header > h4:first-of-type {
    font-size: 14px;
    font-weight: bold;
    color: #0B4374;
}

.study-guide-dashboard_sidebar_schedule_header > h4:last-of-type {
    font-size: 16px;
    font-weight: bold;
    color: #1580DC;
}

.schedule_list {
    height: 100%;
    /* height: 460px; */
    overflow-y: scroll;
    margin-top: 15px;
    /* padding-bottom: 20px; */
}

.schedule_list::-webkit-scrollbar {
    display: none;
}

.schedule_item {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    align-items: center;
    margin: 10px auto;
    border-radius: 16px;
    max-height: fit-content;
    min-height: 220px;
    transition: 0.3s;
    padding: 0 15px;
    box-sizing: border-box;
    gap: 10px;
}

.reset-study-guide-button {
    background: red;
    padding: 5px 10px;
    border: none;
    color: white;
    font-size: 12px;
    border-radius: 5px;
}

/* .schedule_item:first-of-type {
    background-color: #0B4374;
    color: white;
}

.schedule_item:first-of-type > span {
    color: white;
    border-right: 2px solid white;
}

.schedule_item:first-of-type > .schedule_item_details > span:first-of-type {
    color: white;
} */

/* .schedule_item:hover {
    background-color: #0B4374;
    color: white;
    transition: 0.3s;
} */

/* .schedule_item:hover > span,
.schedule_item:hover > .schedule_item_details > span:first-of-type,
.schedule_item:hover > .week-and-checkbox > span {
    color: white;
    transition: 0.3s;
} */

/* .schedule_item:hover > .week-and-checkbox > span {
    border-right: 2px solid white;
    transition: 0.3s; 
}

.schedule_item:hover > .week-and-checkbox >.checkbox {
    border: 1px solid white;
    transition: 0.3s; 
} */

.week-and-checkbox {
    display: flex;
    align-items: center;
    min-width: fit-content;
}

.checkbox {
    border: 1px solid #0B4374;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    /* margin-right: 10px; */
    transition: 0.3s;
}

/* .checkbox:hover {
    background-color: limegreen;
    cursor: pointer;
} */

.schedule_item_details_task_name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100px;
    height: 20px;
    display: block;
}

.schedule_item_details_task_name:hover {
    cursor: pointer;
    text-decoration: underline;
}

.week-and-checkbox > span {
    font-size: 14px;
    font-weight: bold;
    border-right: 2px solid #0B4374;
    /* padding-right: 30px; */
    color: #0B4374;
    width: 90px;
    text-align: center;
    margin-right: 10px;
}

.schedule_item_details {
    padding-left: 10px;
    font-size: 14px;
    color: #0B4374;
    text-align: left;
    width: 100%;
}

.schedule_item_details > span:last-of-type {
    font-size: 12px;
    color: grey;
    font-style: italic;
}

/* Study Guide Summary Report */

.study-guide-dashboard_summary_report_stats {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    margin-top: 20px;
}

.stat_item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stat_item > span {
    font-size: 16px;
    color: #1580DC;

}

.stat_item_outer_ring {
    background: linear-gradient(180deg, #1580DC 0%, #15AAF2 100%);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stat_item_inner_ring {
    background-color: white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.stat_item_inner_ring > span {
    font-size: 20px;
    color: #1580DC;
}

.study-guide-dashboard_topics {
    display: flex;
    flex-direction: column;
    margin-top: 55px;
    height: 180px;
}

.study-guide-dashboard_topics > span {
    font-size: 16px;
    color: #1580DC;
    margin-bottom: 10px;
    margin-top: 10px;
    transition: 1s;
}

.study-guide-dashboard_topics > span:hover {
    background: linear-gradient(90deg, rgba(187, 255, 245, 0.4) 0%, rgba(229, 255, 251, 0.4) 100%);
    /* cursor: pointer; */
    transition: 1s;
}

.study-guide-dashboard-progress {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-top: 25px;
}

.progress_item {
    /* border: 1px solid #02A976; */
    height: 140px;
    width: 250px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.progress_item > span {
    font-size: 16px;
    color: grey;
    margin-top: 10px;
}

.progress_item_outer_ring {
    background-color: #02A976;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.progress_item_inner_ring {
    background-color: #02A976;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
}

.progress_item_inner_ring > span {
    font-size: 20px;
    color: white;
}

.progress_item:nth-of-type(2) > .progress_item_outer_ring {
    background-color: #D98477;
}

.progress_item:nth-of-type(2) > .progress_item_outer_ring > .progress_item_inner_ring {
    background-color: #D98477;
}

.progress_item:nth-of-type(3) > .progress_item_outer_ring {
    background-color: #D67D00;
}

.progress_item:nth-of-type(3) > .progress_item_outer_ring > .progress_item_inner_ring {
    background-color: #D67D00;
}

.week-number {
    color: #0B4374;
    font-size: 14px;
    font-weight: bold;
    border-bottom: 2px solid #0B4374;
    padding-right: 10px;
    width: 90px;
    text-align: center;
    margin-right: 10px;
}

.task-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* align-items: center; */
    width: 100%;
}

.task-header {
    display: flex;
    align-items: center;
    width: 100%;
}

/* course not purchased */
.study-guide-dashboard_sidebar_purchase_message {
    color: #9747FF;
    text-align: left;
    margin-top: 40px;
    padding-left: 10px;
    padding-right: 10px;
}

.study-guide-dashboard_purchase_message {
    background: linear-gradient(180deg, #00A991 0%, #1580DC 100%);
    width: 100%;
    height: 74%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    text-align: center;
}

.study-guide-dashboard_purchase_message > button {
    background-color: #0B4374;
    padding: 10px;
    gap: 10px;
    border: none;
    color: white;
    font-size: 16px;
}

.study-guide-dashboard_purchase_message > p {
    margin-top: 20px;
}

.lock-icon {
    font-size: 70px !important;
}

.lock-icon:hover {
    pointer-events: none !important;
}

/* no study guide setup */
.study-guide-dashboard_no_study_guide_message {
    background: linear-gradient(180deg, #00A991 0%, #1580DC 100%);
    width: 100%;
    height: 74%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    text-align: center;
}

.menu-book-icon {
    font-size: 70px !important;
}

.menu-book-icon:hover {
    pointer-events: none !important;
}

.study-guide-dashboard_sidebar_no_study_guide_message {
    color: #9747FF;
    text-align: left;
    margin-top: 40px;
    padding-left: 10px;
    padding-right: 10px;
}

/* study guide setup (left-panel) */
.studyGuide {
    display: flex;
    flex-direction: column;
}

.ai-icon-container {
    background-color: #1580DC;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    border: 4px solid #90E3F9;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ai-icon {
    color: white;
}

.study-guide-setup_message {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: 5px;
    width: fit-content;
}

.study-guide-setup_message_text {
    color: black;
    border: 2px solid #1580DC99;
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
}

.connector {
    background: linear-gradient(90deg, rgba(144, 227, 249, 0.6) 6.25%, rgba(21, 128, 220, 0.6) 100%);
    width: 16px;
    height: 20px;
}

.study-guide-setup_message_text > button {
    background-color: #1580DC;
    border: none;
    color: white;
    font-size: 16px;
    width: 100%;
    padding-top: 3px;
    padding-bottom: 3px;
}

.study-guide-setup_message_text > p {
    margin-bottom: 0;
}

.study-guide-setup_message_exam_date > button {
    color: white;
    background-color: #1580DC;
    border: none;
    padding: 5px 10px 5px 10px;
    margin-left: 10px;
    font-size: 14px;
}

.study-guide-setup_message_hours_per_week {
    margin-top: 10px;
}

.study-guide-setup_message_hours_per_week > button {
    color: white;
    background-color: #1580DC;
    border: none;
    padding: 5px 10px 5px 10px;
    margin-left: 10px;
    font-size: 14px;
    width: 30px;
}

.study-guide-setup_message_completion_goal{
    margin-top: 10px;
}

.study-guide-setup_message_completion_goal > button {
    color: white;
    background-color: #1580DC;
    border: none;
    padding: 5px 10px 5px 10px;
    margin-left: 10px;
    font-size: 14px;
    width: fit-content;
}

/* study guide sidebar left-side when study guide is setup */

.study-guide-dashboard_sidebar_left_side {
    display: flex;
    flex-direction: column;
}
.study-guide-dashboard_sidebar_progress_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.study-guide-dashboard_sidebar_progress_item > span {
    color: #0B4374;
}

.study-guide-dashboard_sidebar_progress_item_circle > span {
    font-size: 20px;
    color: white;
}

.study-guide-dashboard_sidebar_progress {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid #0B4374;
}

.study-guide-dashboard_sidebar_progress_item_circle {
    width: 75px;
    height: 75px;
    background-color: #0B4374;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.study-guide-dashboard_sidebar_left_side_schedule {
    margin-top: 10px;
    padding-left: 10px;
    border-bottom: 2px solid #0B4374;
    padding-bottom: 10px;
}

.study-guide-dashboard_sidebar_left_side_schedule > h4 {
    font-size: 28px;
    color: #0B4374;
    text-decoration: underline;
    margin-bottom: 10px;
}

.study-guide-dashboard_sidebar_left_side_schedule > h5 {
    font-size: 24px;
    color: #1580DC;
    margin-bottom: 10px;
    margin-left: 20px;
}

.study-guide-dashboard_sidebar_left_side_schedule > h6 {
    font-size: 16px;
    color: #1580DC;
    margin-bottom: 10px;
    margin-left: 20px;
}

.study-guide-dashboard_sidebar_left_side_summary {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}

.study-guide-dashboard_sidebar_left_side_summary_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.study-guide-dashboard_sidebar_left_side_summary_item_circle {
    background-color: #0B4374;
    border-radius: 50%;
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.study-guide-dashboard_sidebar_left_side_summary_item_circle > span {
    font-size: 20px;
    color: white;
}

.study-guide-dashboard_sidebar_left_side_summary_item > span {
    color: #0B4374;
}

.study-guide-dashboard_sidebar_left_side_message {
    border-bottom: 2px solid #0B4374;
    padding-bottom: 20px;
}

.study-guide-dashboard_sidebar_left_side_buttons {
    display: flex;
    /* justify-content: space-evenly; */
    justify-content: center;
    /* margin-bottom: 10px; */
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    border-bottom: 2px solid #0B4374;
    padding-bottom: 20px;
    padding-top: 20px;
}

.study-guide-dashboard_sidebar_left_side_buttons_container {
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
}

.study-guide-dashboard_sidebar_left_side_buttons > button {
    background-color: #0B4374;
    color: white;
    border: none;
    padding: 10px 20px 10px 20px;
    font-size: 14px;
    border-radius: 12px;
}

.study-guide-dashboard_sidebar_left_side_buttons_container > button {
    background-color: #0B4374;
    color: white;
    border: none;
    padding: 10px 0px 10px 0px;
    font-size: 14px;
    width: 150px;
    border-radius: 12px;
}

.schedule_item_tasks {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.schedule_item_details_task_name {
    font-size: 14px;
    cursor: pointer;
    color: #1580DC;
    width: 100%;
    margin-left: 10px;
}

.schedule_item_details_task_name:hover {
    text-decoration: underline;
}

@media (max-width: 318px) {
    .study-guide-dashboard_topics > span {
        margin: 5px;
    }
}

@media (max-width: 407px) {
    .study-guide-dashboard_sidebar_progress_item > span {
        word-break: break-word;
        text-align: center;
        width: 90%;
        display: block;
    }

    .study-guide-dashboard_sidebar_left_side_summary {
        flex-wrap: wrap;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        gap: 50px;
    }
}

@media(max-width: 391px) {
    .study-guide-dashboard_sidebar_left_side_summary {
        gap: 20px;
    }

    .study-guide-dashboard_sidebar_left_side_buttons_container {
        flex-direction: column;
    }
    .study-guide-dashboard_sidebar_left_side_buttons {
        flex-direction: column !important;
        align-items: center;
    }
}

@media(max-width: 342px) {
    .study-guide-dashboard_sidebar_left_side_summary {
        gap: 10px;
    }
}

@media(max-width: 323px) {
    .study-guide-dashboard_sidebar_left_side_summary {
        flex-wrap: nowrap;
        flex-direction: column;
    }
}

@media(max-width: 309px) {
    .study-guide-dashboard_sidebar_left_side_schedule > h5,
    .study-guide-dashboard_sidebar_left_side_schedule > h6 {
        margin-left: 0;
    }
}

@media (max-width: 291px) {

    .study-guide-dashboard_sidebar_left_side_buttons {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .study-guide-dashboard_sidebar_left_side_buttons > button {
        padding: 10px !important;
        width: 100px;
        margin-bottom: 10px;
    }

    .study-guide-dashboard_sidebar_left_side_buttons_container {
        display: flex;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .study-guide-dashboard_sidebar_left_side_buttons_container > button {
        width: 100px;
        padding: 10px !important;
        margin-bottom: 10px;
        margin-top: 10px;
        margin-right: 0 !important;
    }
}

@media (max-width: 768px) {
    .study-guide-dashboard {
        height: fit-content;
    }

    .study-guide-dashboard_topics {
        margin-top: 20px;
    }
}

@media (min-width: 1350px) {
    .study-guide-dashboard_sidebar_left_side_buttons {
        /* margin-top: 50px; */
    }
}

@media (max-width: 697px) {
    .study-guide-dashboard_sidebar_left_side_buttons {
    }

    .study-guide-dashboard_sidebar_left_side_buttons_container > button {
        margin-bottom: 10px;
    }
}

@media (max-width: 1491px) {
    .study-guide-dashboard_sidebar_left_side_buttons {
        /* flex-direction: column !important; */
        flex-wrap: wrap;
    }
}

.swal2-text-center {
    text-align: center !important;
}