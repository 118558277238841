.flashcard-favorites-container {
    height: 100%;
    width: 100%;
    /* border: 1px solid blue; */
}

.flashcard-favorites-container > h1 {
    /* color: #0B4475; */
    font-weight: bold;
    word-wrap: break-word;
    font-size: 25px;
}

.flashcard-favorites-list {
    /* border: 1px solid red; */
    overflow-y: scroll;
    height: 93% !important;
    width: 100%;
}

.flashcard-favorites-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border: 1px solid #0B4475;
    text-align: initial !important;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
}

.flashcard-favorites-item-votes {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.flashcard-favorites-item-label {
    font-weight: bold;
    color: #0B4475;
}

@media (max-height: 865px) {
    .flascard-favorites-list {
        height: 99% !important;
    }
}