/* Containers */

.instructor-dashboard_container {
    display: flex;
    height: 60vh;
    width: 65vw;
    border: 1px solid grey;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background-color: #f7f7f7;
}

.instructor-dashboard_content_home_icon {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #0B4475;
    cursor: pointer;
}

.instructor-dashboard_content_home_icon.MuiSvgIcon-root {
    position: absolute !important;
    top: 10px !important;
    right: 10px !important;
    color: #0B4475;
    cursor: pointer;
}

.instructor-dashboard {
    height: 100%;
    width: 100%;
    display: flex;
}

.instructor-dashboard_content {
    position: relative;
    height: 100%;
    width: 75%;
    padding: 40px 20px 10px 20px;
}



/* Sidebar */

.instructor-dashboard_sidebar {
    border-right: 1px solid grey;
    height: 100%;
    width: 35%;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    overflow-y: scroll;
}

.instructor-dashboard_sidebar::-webkit-scrollbar {
    display: none;
}

#instructor-dashboard_sidebar_courses_list::-webkit-scrollbar {
    width: 8px;
    display: block;
}

#instructor-dashboard_sidebar_courses_list::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
}

#instructor-dashboard_sidebar_courses_list::-webkit-scrollbar-thumb {
    background: #0B4475;
    border-radius: 10px;
}

.instructor-dashboard_sidebar > h2 {
    /* background: linear-gradient(96.83deg, #1580DC 0%, #0B4475 100%); */
    background: linear-gradient(96.83deg, #0B4475 0%, #1580DC 100%);
    color: white;
    width: 100%;
    padding: 10px 20px;
    text-align: center;
    font-size: 20px;
}

#instructor-dashboard_sidebar_courses_list {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    gap: 5px;
    border-bottom: 2px solid #0B4475;
    margin-right: 20px;
    overflow-y: scroll;
    height: 190px;
    margin-left: 20px;
    overflow-x: hidden;
}

#instructor-dashboard_sidebar_courses_list > span {
    /* color: #0B4475; */
    font-size: 18px;
    transition: 0.3s;
    width: 95%;
    padding: 20px;
    border-radius: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.5px;
}

#instructor-dashboard_sidebar_courses_list > span:hover {
    color: white;
    background-color: #0B4475;
    transition: 0.3s;
    cursor: pointer;
}

.instructor-dashboard_sidebar_courses_title {
    margin-top: 35px;
    margin-bottom: 15px;
    margin-left: 20px;
    /* color: #0B4475; */
    font-weight: bold;
    font-size: 20px;
    text-decoration: underline;
}

.instructor-dashboard_sidebar_connect_title,
.instructor-dashboard_sidebar_reported_issues_title {
    margin-top: 55px;
    margin-left: 20px;
    color: #0B4475;
    width: fit-content;
}

.instructor-dashboard_sidebar_reported_issues_title {
    margin-bottom: 30px;
}

.instructor-dashboard_sidebar_connect_title:hover,
.instructor-dashboard_sidebar_reported_issues_title:hover {
    cursor: pointer;
    text-decoration: underline;
}

/* Content */

.instructor-dashboard_content > h2 {
    /* margin-left: 20px; */
    color: #0B4475;
    font-weight: bold;
    word-wrap: break-word;
}

.instructor-dashboard_content_total_students,
.instructor-dashboard_content_total_courses,
.instructor-dashboard_content_favorite_flashcards,
.instructor-dashboard_content_favorite_quizzes {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
    align-items: center;
}

/* Media Queries */

@media (max-width: 1183px) and (max-height: 881px) {
    .instructor-dashboard_sidebar_connect_title {
        margin-top: 35px !important;
    }
}

@media (max-width: 965px) {
    .instructor-dashboard_container {
        /* height: 900px; */
        height: fit-content !important;
    }

    .instructor-dashboard {
        flex-direction: column;
        width: 100%;
    }

    .instructor-dashboard_sidebar {
        border-bottom: 1px solid #0B4475;
        border-right: none;
        width: 100%;
        overflow-y: initial;
    }

    .instructor-dashboard_sidebar_connect_title {
        margin-top: 30px;
    }

    .instructor-dashboard_sidebar_reported_issues_title {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    #instructor-dashboard_sidebar_courses_list {
        border-bottom: none;
        border: 1px solid #0B4475;
        border-radius: 5px;
        padding-top: 5px;
        overflow-y: scroll;
        width: 70%;
    }

    #instructor-dashboard_sidebar_courses_list > span {
        min-width: 30%;
        max-width: fit-content;
        margin-left: 10px;
    }

    .instructor-dashboard_content {
        width: 100%;
        justify-content: space-between;
        padding-top: 10px;
        padding-left: 20px;
        text-align: center;
        gap: 30px;
    }
}

@media (max-width: 550px) {

    .instructor-dashboard_content_total_students,
    .instructor-dashboard_content_total_courses,
    .instructor-dashboard_content_favorite_flashcards,
    .instructor-dashboard_content_favorite_quizzes {
        flex-direction: column;
        margin-right: 0px;
    }

    .instructor-dashboard_content > h2 {
        margin-bottom: 20px;
    }

    .instructor-dashboard_content_total_students_title,
    .instructor-dashboard_content_total_courses_title,
    .instructor-dashboard_content_favorite_flashcards_title,
    .instructor-dashboard_content_favorite_quizzes_title {
        margin-top: 15px;
        margin-bottom: 10px;
    }

    .instructor-dashboard_content {
        padding-left: 0px;
    } 
}

@media (max-width: 731px) {
    .instructor-dashboard_container {
        width: 90vw;
    }
}

@media (max-width: 1261px) {
    .instructor-dashboard_container {
        width: 90vw;
    }
}

@media (max-width: 550px) {
    .instructor-dashboard_content {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 0 !important;
        padding-top: 30px !important;
    }
}

/* @media (max-height: 865px) {
    .instructor-dashboard_container {
        height: 80dvh;
    }

    .instructor-dashboard_content {
        padding: 40px 20px 10px 20px !important;
    }
} */

@media (max-height: 920px) {
    .instructor-dashboard_container {
        height: 80dvh;
    }

    .instructor-dashboard_content {
        padding: 40px 20px 10px 20px !important;
    }
}

@media (max-height: 641px) {
    .instructor-dashboard_sidebar_connect_title,
    .instructor-dashboard_sidebar_reported_issues_title {
        margin-top: 20px !important;
        margin-left: 20px;
        color: #0B4475;
        width: fit-content;
    }

    .instructor-dashboard_container {
        margin-top: 0px !important;
    }
}