footer {
    background-color: #eeeeee;
    margin-top: 50px;
    box-shadow: 0 -3px 6px #00000029;
}
.footer_box {
    margin: 50px 0;
}
.footer_box h4 {
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 16px;
}
.footer_menu {
    list-style: none;
    padding-left: 0;
}
.footer_menu li {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
}
.footer_menu a {
    display: inline-block;
    width: 100%;
    color: #000;
}
.footer_contact {
    display: flex;
    align-items: center;
    color: #0b4475;
    margin-bottom: 10px;
}
.footer_contact figure {
    width: 18px;
    margin-right: 10px;
}
.footer_bottom {
    padding: 20px 0;
    display: flex;
    align-items: center;
}
.social_media {
    display: flex;
    align-items: center;
    margin-left: auto;
}
.social_media a {
    margin-left: 15px;
    width: 24px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
    footer {
        padding-top: 20px;
    }
    .footer_box {
        margin: 15px 0;
    }
    .footer_bottom {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 10px;
    }
    .social_media {
        order: 1;
        margin: 0 0 10px;
    }
    .copyright {
        order: 2;
    }
}
