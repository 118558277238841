.lng-container {
    margin: auto 0px;
    margin-right: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lng-labels {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 68px;
    height: 34px;
    background: #fff;
    border-radius: 5px;
    position: relative;
}

.lng-labels .lng-button,
.lng-labels .lng-button {
    content: '';
    position: absolute;
    width: 34px;
    height: 34px;
    border-radius: 5px 0 0 5px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #0b4475;
    box-shadow: inset 0 0 10px #d1caca;
}

.lng-labels .lng-button.right {
    right: 0px;
    border-radius: 0 5px 5px 0;
}

.lng-labels .lng-button.active {
    background-color: #0b4475;
    box-shadow: none;
}

.lng-labels .lng-text {
    color: #000;
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.lng-labels .lng-button.active .lng-text {
    color: #fff;
    font-weight: bold;
}

@media only screen and (max-width: 768px) {
    .lng-container {
        margin-left: 20px;
        margin-right: 0px;
    }
}

.language-btn-container {
    background-color: white;
    border-radius: 25px;
    /* padding: 30px; */
    /* width: 100px; */
    width: fit-content;
    /* height: 40px; */
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    outline: none !important;
    margin-right: 20px;
}

.language-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #dedede;
    /* border-radius: 20px; */
    border-radius: 25px;
    /* padding: 3px 0; */
    border: 2px solid #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.flag {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    margin-left: 3px;
}

.language-text {
    font-size: 14px;
    /* margin-right: 8px; */
    padding: 5px 10px 5px 10px;
    font-family: 'Metropolis', sans-serif;
    /* Assuming you have this font */
}