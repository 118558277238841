.live_session {
    /* margin-top: 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* margin: auto; */
    height: 100%;
    min-height: 386px;
}

.live_session-title {
    font-size: 24px;
    font-weight: 900;
    color: var(--primary);
    margin-bottom: 16px;
}

.live_session-description {
    font-size: var(--font-size-16);
    line-height: auto;
    /* margin-bottom: 10px; */
}

.live_session-countdown-time {
    font-weight: 700;
    text-decoration: underline;
    color: var(--primary);
}

.live_session-date {
    font-size: 16px;
    /* margin-top: 16px; */
}

.live_session-date-title,
.live_session-countdown-title {
    font-weight: 700;
}

.live_session-date-value,
.live_session-countdown-value {
    font-weight: 500;
}

.live_session-button {
    /* margin-top: 16px; */
    border-radius: 0;
    color: white;
    padding: 4px 10px;
    /* box-shadow: none;
    outline: none; */
    border: none;
}

.live_session-button:hover {
    background-color: var(--primary-80);
}
