#ai-features-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

#chapter-select {
    width: 350px;
    text-align: center;
    height: 30px;
    border-radius: 8px;
    color: white;
    font-size: 20px;
    background: #0b4475;
    text-overflow: ellipsis;
}

#feature-select {
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: fit-content;
}

.feature-select-option>span {
    font-size: 20px;
    color: white;
}

.radio-button {
    border: 1px solid #0b4475;
    margin-right: 10px;
    border-radius: 50%;
    width: 10px;
    height: 10px;
}

.feature-select-option {
    display: flex;
    align-items: center;
    cursor: pointer;
    background: #0b4475;
    width: 150px;
    justify-content: center;
    border-radius: 8px;
    margin-left: 10px;
    margin-right: 10px;
}

#header-options {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loading-spinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.loading-spinner div {
    animation: loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.loading-spinner div:after {
    content: ' ';
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #0b4475;
    margin: -4px 0 0 -4px;
}

.loading-spinner div:nth-child(1) {
    animation-delay: -0.036s;
}

.loading-spinner div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.loading-spinner div:nth-child(2) {
    animation-delay: -0.072s;
}

.loading-spinner div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.loading-spinner div:nth-child(3) {
    animation-delay: -0.108s;
}

.loading-spinner div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.loading-spinner div:nth-child(4) {
    animation-delay: -0.144s;
}

.loading-spinner div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.loading-spinner div:nth-child(5) {
    animation-delay: -0.18s;
}

.loading-spinner div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.loading-spinner div:nth-child(6) {
    animation-delay: -0.216s;
}

.loading-spinner div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.loading-spinner div:nth-child(7) {
    animation-delay: -0.252s;
}

.loading-spinner div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.loading-spinner div:nth-child(8) {
    animation-delay: -0.288s;
}

.loading-spinner div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@keyframes loading-spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}