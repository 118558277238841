.modal-content {
    border-radius: 20px;
}
.modal-body {
    padding: 0;
}
.lrf_modal {
    padding: 25px;
}
.instructor-form {
    width: 100%;
    padding: 10px;
}
.instructor-form h2 {
    font-weight: 800;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
}

.lrf_modal h2 {
    font-weight: 800;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
}
.lrf_subtext {
    color: #9f9f9f;
    text-align: center;
    margin-bottom: 25px;
}
.lrf_subtext a {
    color: #000;
}
.form_flx {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.form_flx a {
    border-bottom: 1px solid #dfdfdf;
}
.form_accept .form-check-label {
    color: #9f9f9f;
    font-weight: 600;
}
.lrf_separator {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}
.lrf_separator span {
    color: #9f9f9f;
    font-size: 14px;
    padding: 0 20px;
    white-space: nowrap;
}
.lrf_separator_line {
    height: 1px;
    width: 100%;
    background-color: #dfdfdf;
}
.signup_modal .modal-dialog {
    max-width: 700px;
}
.askdoubt_modal .modal-dialog {
    max-width: 100%;
}
.askdoubt_modal .modal-content {
    max-width: 80%;
    margin: 40px auto;
}
.askdoubt_modal .close {
    top: 20px;
    right: 10px;
}
.askdoubt_modal .course_btns {
    justify-content: flex-end;
    display: flex;
}
.askdoubt_modal .course_btns .btn_primary {
    margin-right: 0;
}
.askdoubt_modal .form-control {
    border: 1px solid #eee;
    background-color: #fff;
}
.askdoubt_modal .form-control:focus {
    background-color: #fff;
}

.video_modal .modal-dialog {
    max-width: 80%;
}
.video_modal .modal-content {
    overflow: hidden;
    border: none;
}
.video_modal video {
    width: 100%;
    height: 100%;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
    /* .react_modal {
        width: 90%;
    }
    .lrf_modal {
        padding: 25px;
    }
    .form_row {
        margin: 0;
    }
    .form_col {
        width: 100%;
        margin: 0;
    }
    .form_flx {
        flex-wrap: wrap;
    }
    .form_accept {
        width: 100%;
    }
    .form_flx a {
        width: 100%;
        margin-top: 15px;
    }
    .video_modal .modal-dialog,
    .askdoubt_modal .modal-content {
        max-width: 95%;
        margin: 40px auto;
    } */
}
