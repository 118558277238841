.course-materials {
    height: 100%;
    width: 100%;
    /* border: 1px solid red; */
}

.course-materials > h1 {
    color: #0B4475;
    font-weight: bold;
    word-wrap: break-word;
    margin-top: 20px;
    font-size: 24px;
}

.course-materials-input {
    display: flex;
    align-items: center;
    border: 1px solid #0B4475;
    width: fit-content;
    padding: 10px;
    border-radius: 5px;
    margin-top: 20px;
}

.course-materials-input > button {
    background-color: #0B4475;
    color: white;
    padding: 5px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

.course-materials-input > button:hover {
    background-color: #1580DC;
    color: white;
}

.course-materials-list {
    height: 75%;
    width: 100%;
    overflow-y: scroll;
}

.course-materials-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #0B4475;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    width: 95%;
}

.course-materials-list-item > h4 {
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.course-materials-list-item-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.course-materials-list-item-buttons > * {
    background-color: #0B4475;
    color: white;
    padding: 5px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    width: 50px;
}