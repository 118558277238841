.swiper_box {
    position: relative;
    display: inline-block;
    width: 100%;
    overflow: hidden;
}
.banner_meta {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    max-width: 700px;
}
.banner_meta h2 {
    color: #fff;
    font-size: 40px;
    margin-bottom: 0;
    font-weight: 600;
    padding: 0 15px;
}
.banner_description {
    color: #fff;
    font-weight: 600;
}
.banner_meta h2 + .banner_description {
    color: #fff;
    margin-bottom: 10px;
}
.banner_meta .btn_primary {
    margin-top: 20px;
}
.mid_banner {
    background: url(../image/mid-banner.png);
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    overflow: hidden;
}
.mid_inner {
    display: flex;
    align-items: center;
}
.mid_content {
    color: #fff;
    flex: 1;
}
.mid_content p {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 0;
}
.mid_content h2 {
    font-size: 30px;
    font-weight: 700;
}
.mid_content .btn_tertiary {
    padding: 10px 40px;
}
.mid_img {
    flex: 1;
}

.homepage-advert-img {
    width: 100%;
    height: 250px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    .banner_meta h2 {
        font-size: 34px;
    }
}
@media screen and (max-width: 991px) {
    .view_all_link {
        font-size: 16px;
    }
}
@media screen and (max-width: 767px) {
    .banner_meta h2 {
        font-size: 14px;
        margin-bottom: 10px;
    }
    .banner_meta .btn_primary {
        padding: 7px 20px;
    }
    .mid_banner.p_100 {
        padding: 30px;
    }
    .mid_inner {
        flex-wrap: wrap;
    }
    .mid_content {
        order: 2;
        width: 100%;
        flex: auto;
        text-align: center;
    }
    .mid_img {
        order: 1;
        padding-left: 0;
        width: 100%;
        flex: auto;
        margin-bottom: 30px;
    }
    .mid_content p {
        font-size: 16px;
    }
    .mid_content h2 {
        font-size: 20px;
    }

    .homepage-advert-img {
        margin-top: 15px;
    }
}
