/* .flashcard-list {
    height: 446px;
    overflow-y: auto;
} */

.flashcard {
    width: 100%;
    /* height: 100%; */
    /* height: 700px; */

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    background: var(--light-blue);
    /* overflow-y: auto; */
    /* overflow: hidden; */
    /* gap: 20px; */
    /* border: 1px solid red; */
}

.flashcard__header {
    background: #bfcedb;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    /* text-align: center; */
    position: relative;
    height: 60px;
    padding-right: 20px;
}

.flashcard__header h1 {
    color: var(--primary);
    font-weight: 600;
    letter-spacing: 3px;
    text-transform: uppercase;
    /* text-align: center; */
    font-size: 24px;
    /* margin: 0 auto; */

    position: absolute; /* Use absolute positioning */
    top: 50%; /* Move down 50% of the parent height */
    left: 50%; /* Move right 50% of the parent width */
    transform: translate(-50%, -50%); /* Adjust position to center */
}

.icon-wrapper {
    position: relative;
    z-index: 1000;
}

.flashcard__content {
    width: 100%;
    /* border: 1px solid red; */
    /* height: 100%; */
    height: 386px;
    padding: 20px;

    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.flashcard__content-menu {
    display: flex;
    align-items: center;
    justify-content: center;

    /* margin-top: 20px; */
    gap: 24px;
    width: 100%;

    /* border: 1px solid red; */
}

.flashcard__content-menu-icon {
    background-color: rgba(255, 255, 255, 0.2);
    height: 56px;
    width: 56px;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    /* border: 1px solid red; */
}

.flashcard__content-menu-icon.active {
    background-color: white;
}

/* Optional: Add hover effect */
.flashcard__content-menu-icon:hover {
    background-color: rgba(255, 255, 255, 0.4);
}

.flashcard__content-menu-icon.active:hover {
    background-color: rgba(255, 255, 255, 0.9);
}

.flashcard__content-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 100%; */

    height: 100%;
    width: 100%;
    /* min-width: 400px; */
    /* gap: 100px; */
    /* gap: calc(100% - 40px); */

    /* border: 1px solid red; */

    /* padding: 0 20px; */

    position: relative;
}

.flashcard__content-info-content {
    font-size: 18px;
    line-height: 20px;
    width: 400px;
    font-weight: 400;
    color: #fff;
    text-align: center;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.flashcard__content-info-icon {
    width: 48px;
    height: 48px;
    border: 2px solid #fff;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flashcard__content-info-icon:hover {
    background-color: rgba(255, 255, 255, 0.4);
}

.flashcard__content-info-placeholder {
    width: 48px; /* Same width as the icon */
    height: 48px; /* Same height as the icon */
}

.flashcard__content-rates {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.flashcard__content-rates-icon {
    width: 42px;
    height: 42px;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
}

.flashcard__content-rates-icon:hover {
    background-color: rgba(255, 255, 255, 0.4);
}

.flashcard__content-rates-icon.active {
    background-color: rgba(255, 255, 255, 0.9);
}

.flashcard__counter {
    position: absolute;
    bottom: 18px;
    right: 22px;
    display: flex;
    /* padding-right: 4px; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;

    color: #fff;
    font-weight: 500;
    width: 64px;
    height: 64px;
    /* background-color: rgba(255, 255, 255, 0.2); */
    border-radius: 60px;
}

.flashcard__counter-content {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: center;
    position: relative;
}

.flashcard__counter-content-numerator {
    position: absolute;
    top: 12px;
    left: 20px;
    font-size: 22px;
}

.flashcard__counter-content span {
    font-size: 24px;
}

.flashcard__counter-content-denominator {
    font-weight: 700;
    font-size: 32px;
    position: absolute;
    bottom: 2px;
    right: 10px;
}

.report-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.report-modal {
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.report-modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.report-modal__header h2 {
    margin: 0;
    font-size: 20px;
    color: #333;
}

.report-modal__description {
    color: #666;
    margin-bottom: 16px;
}

.report-modal__textarea {
    width: 100%;
    min-height: 150px;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 16px;
    resize: vertical;
    font-family: inherit;
}

.report-modal__submit {
    background-color: #0b4374;
    color: white;
    border: none;
    padding: 10px 24px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s;
}

.report-modal__submit:hover {
    background-color: #083058;
}

@media (max-width: 568px) {
    .flashcard__content-info-content {
        width: 250px;
    }
}

@media (max-width: 470px) {
    .flashcard__content-info-content {
        width: 200px;
    }
}
