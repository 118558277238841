#chatbot-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    transform: scaleX(-1);
    font-size: 45px;
    color: white;
    cursor: pointer;
    background-color: #0b4475;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    font-size: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 999;
}

#chatbot-container {
    position: fixed;
    bottom: 90px;
    right: 20px;
    height: 704px;
    width: 400px;
    border-radius: 16px;
    background: rgb(11, 68, 117);
    background: linear-gradient(180deg, rgba(11, 68, 117, 1) 2%, rgba(255, 255, 255, 1) 100%);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    z-index: 100;
}

#chatbot-nav {
    background: white;
    width: 100%;
    height: 70px;
    position: absolute;
    bottom: 0;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border-top: 1px solid rgba(128, 128, 128, 0.388);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.nav-icon {
    color: #0b4475;
    font-size: 35px;
    cursor: pointer;
}

#home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    border-radius: 16px;
}

#home-header {
    display: flex;
    flex-direction: column;
    margin-top: 150px;
    align-items: flex-start;
}

#home-header > span {
    color: white;
    font-size: 35px;
    padding: 0;
    margin: 0;
}

#ask-a-question {
    background-color: white;
    width: 350px;
    height: 60px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

#ask-a-question > div > span:first-of-type {
    color: #0b4475;
    font-weight: bold;
    font-size: 15px;
    width: fit-content;
    margin-left: 10px;
}

#ask-a-question > div > span:last-of-type {
    color: grey;
    font-size: 13px;
    margin-left: 10px;
}

#ask-a-question-icon {
    color: #0b4475;
    font-size: 20px;
    margin-left: auto;
    margin-right: 10px;
}

#D-icon-home {
    position: absolute;
    top: 10px;
    left: 15px;
    font-size: 50px;
    color: white;
}

#D-icon-help {
    font-size: 80px;
    color: #0b4475;
}

#help-container {
    /* background-color: white; */
    height: 100%;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
}

#help-nav {
    background-color: #0b4475;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 94px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom: 1px solid white;
}

#help-nav > span {
    color: white;
    font-weight: bold;
    font-size: 17px;
    letter-spacing: 5px;
}

#help-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
}

#help-header > span:first-of-type {
    color: #0b4475;
    font-weight: bold;
    font-size: 20px;
}

#help-header > span:last-of-type {
    color: grey;
    font-size: 13px;
}

#back-arrow {
    position: absolute;
    left: 20px;
    font-size: 30px;
    color: white;
    cursor: pointer;
}

#help-input {
    border-radius: 18px;
    border: 1px solid rgba(128, 128, 128, 0.441);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 90%;
    height: 48px;
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
    /* margin-left: 20px; */
    padding-left: 10px;
}

#messages-container {
    overflow-y: scroll;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#messages-container::-webkit-scrollbar {
    display: none;
}

.message {
    background-color: #0b4475;
    padding: 5px;
    width: fit-content;
    border-radius: 8px;
    margin-left: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    max-width: 69%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.message > span {
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 13px;
}

.faq {
    display: flex;
    width: 90%;
    width: fit-content;
    justify-content: center;
    padding: 10px;
    flex-wrap: wrap;
    border-top: 1px solid rgba(128, 128, 128, 0.388);
}

.faq > span {
    transition: 0.2s;
    color: white;
    border-radius: 8px;
    text-align: center;
    font-size: 15px;
    padding: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
    cursor: pointer;
    background-color: #0b4475;
    width: 160px;
    white-space: nowrap;
    margin-left: 1px;
    margin-right: 1px;
}

.faq > span:hover {
    transition: 0.2s;
    background-color: rgb(0, 123, 255);
}

.message-ai-img {
    width: 35px;
    height: 25px;
    margin-left: 10px;
}

.email-input {
    width: 250px;
    border: 2px solid #0b4475;
    border-radius: 8px;
    outline: none;
    text-align: center;
    height: 30px;
    color: #0b4475;
}

.email-input::placeholder {
    color: #0b4475;
}

.email-submit-icon {
    color: #0b4475;
    font-size: 20px;
    margin-left: 10px;
    cursor: pointer;
    transition: 0.2s;
}

.email-submit-icon:hover {
    transition: 0.2s;
    color: rgb(0, 123, 255);
}

.cs-form-textarea {
    resize: none;
    height: 250px;
    width: 90%;
    margin-top: 10px;
    border: 2px solid #0b4475;
    border-radius: 8px;
    color: #0b4475;
    outline: none !important;
}

.cs-form-input {
    width: 250px;
    border: 2px solid #0b4475;
    border-radius: 8px;
    outline: none;
    text-align: center;
    height: 30px;
    color: #0b4475;
}

.cs-form-button {
    transition: 0.2s;
    color: white;
    border-radius: 8px;
    text-align: center;
    font-size: 15px;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    background-color: #0b4475;
    border: none;
    width: 160px;
    white-space: nowrap;
    outline: none !important;
}

.cs-form-button:hover {
    transition: 0.2s;
    background-color: rgb(0, 123, 255);
}
