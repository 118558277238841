.course-quizzes {
    height: 100%;
    width: 100%;
}

.course-quizzes > h1,
.course-quizzes > h2 {
    /* color: #0B4475; */
    font-weight: bold;
    word-wrap: break-word;
    margin-top: 20px;
    font-size: 20px;
}

.quiz-create {
    display: flex;
    flex-direction: column;
    width: 90%;
}

.quiz-create-question,
.quiz-create-correct-answer,
.quiz-create-incorrect-answer,
.quiz-create-explanation {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.quiz-create-question > span,
.quiz-create-correct-answer > span,
.quiz-create-incorrect-answer > span,
.quiz-create-explanation > span {
    margin-right: 10px;
    font-weight: bold;
    /* color: #0B4475; */
    font-size: 24px;
    width: 25px;
}

.quiz-create-question > input,
.quiz-create-correct-answer > input,
.quiz-create-incorrect-answer > input,
.quiz-create-explanation > input {
    width: 100%;
    border: 1px solid #0B4475;
    border-radius: 5px;
    padding: 10px;
    height: 40px;
}

.quiz-create-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
}

.quiz-create-footer > select {
    width: 70%;
    border: 1px solid #0B4475;
    border-radius: 5px;
    padding: 10px;
    height: 45.59px;
    margin-right: 20px;
}

.quiz-create-footer > button {
    background-color: #0B4475;
    color: white;
    padding: 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    align-self: flex-end;
}

.fetch-quizzes-button {
    background-color: #0B4475;
    color: white;
    padding: 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    align-self: flex-end;
    margin-top: 30px;
}

.fetch-quizzes-button:hover {
    background-color: #1580DC;
    color: white;
}

.quiz-details {
    text-align: left;
    padding: 10px 20px;
}

.quiz-item {
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.quiz-item strong {
    word-break: normal;
    word-wrap: break-word;
    hyphens: auto;
    margin-right: 20px;
    min-width: 150px;
    color: #0B4475;
}

.quiz-item span {
    flex: 1;
    text-align: right;
    word-break: break-word;
    white-space: normal;
    padding-left: 10px;
}

.quiz-divider {
    margin: 20px 0;
    border: 0;
    border-top: 2px solid #0B4475;
}

.quiz-list-container {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.custom-swal-title {
}

.reported-issues-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
}

.reported-issues-empty > h3 {
    color: #0B4475;
    font-size: 24px;
}

@media (max-width: 490px) {
    .swal2-html-container {
        margin: 0 !important;
    }
}

@media (max-width: 407px) {
    .quiz-details {
        padding: 0 !important;
    }

    .quiz-item span {
        white-space: normal;
        width: fit-content !important;
        min-width: 0 !important;
        font-size: 14px !important;
        padding-left: 0 !important;
    }

    .quiz-item strong {
        margin-right: 0 !important;
        width: 90px !important;
        min-width: 0 !important;
        font-size: 14px !important;
        word-break: break-word;
    }
}

@media (max-height: 705px) {
    .quiz-list-container {
        max-height: 50vh !important;
        overflow-y: auto;
        padding: 10px;
    }
}

.quiz-actions button:hover {
    opacity: 0.8;
    transform: scale(1.1);
    transition: all 0.2s ease;
}

.input-group {
    margin-bottom: 15px;
    text-align: left;
}

.input-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
    width: 100%;
}

.input-group .swal2-input {
    margin: 0;
    display: block;
    width: 100%;
}

/* Override any SweetAlert2 default styles */
.swal2-popup .input-group input.swal2-input {
    margin: 0;
    display: block;
    width: 100%;
}

@media screen and (max-height: 737px) {
    .large-swal-container {
        max-height: 80vh !important;
        overflow-y: auto !important;
    }

    .swal2-html-container {
        max-height: 60vh !important;
        overflow-y: auto !important;
    }

    .input-group {
        margin-bottom: 10px; /* Slightly reduce spacing between inputs */
    }
}