@import url('https://fonts.googleapis.com/css2?family=Karla:wght@300;400;600;700;800&display=swap');

@font-face {
    font-family: Metropolis;
    src: url(../fonts/Metropolis-Thin.otf);
    font-style: normal;
    font-weight: 200;
}

@font-face {
    font-family: Metropolis;
    src: url(../fonts/Metropolis-Light.otf);
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: Metropolis;
    src: url(../fonts/Metropolis-Regular.otf);
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: Metropolis;
    src: url(../fonts/Metropolis-Medium.otf);
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: Metropolis;
    src: url(../fonts/Metropolis-SemiBold.otf);
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: Metropolis;
    src: url(../fonts/Metropolis-Bold.otf);
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: Metropolis;
    src: url(../fonts/Metropolis-ExtraBold.otf);
    font-style: normal;
    font-weight: 800;
}

@font-face {
    font-family: Metropolis;
    src: url(../fonts/Metropolis-Black.otf);
    font-style: normal;
    font-weight: 900;
}

html,
body {
    height: 100%;
    font-size: 16px;
}

body {
    font-family: 'Metropolis', sans-serif;
    font-weight: 400;
    line-height: 1.6;
    color: #000;
    text-align: left;
    background-color: #fff;
    letter-spacing: 0;
}

#root {
    overflow-x: hidden;
}

main {
    display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Metropolis', sans-serif;
    font-weight: 700;
    letter-spacing: 0;
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.8rem;
}

h3 {
    font-size: 1.6rem;
}

h4 {
    font-size: 1.4rem;
}

h5 {
    font-size: 1.2rem;
}

h6 {
    font-size: 1rem;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

pre {
    font-family: monospace, monospace;
    font-size: 1em;
}

a {
    background-color: transparent;
}

a:hover {
    text-decoration: none;
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
}

b,
strong {
    font-weight: 800;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

img {
    border-style: none;
    width: 100%;
}

figure {
    margin: 0;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

/* button,
[type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button;
} */

/* button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
} */

/* button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
    outline: 1px dotted ButtonText;
} */

fieldset {
    padding: 0.35em 0.75em 0.625em;
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

textarea {
    overflow: auto;
}

[type='checkbox'],
[type='radio'] {
    box-sizing: border-box;
    padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
    height: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

[type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

details {
    display: block;
}

summary {
    display: list-item;
}

template {
    display: none;
}

[hidden] {
    display: none;
}

::-webkit-input-placeholder,
::-webkit-select-placeholder,
:-moz-placeholder,
:-ms-input-placeholder {
    color: #9f9f9f !important;
    opacity: 1;
}

.mt_10 {
    margin-top: 10px;
}

.mr_10 {
    margin-right: 10px;
}

.mb_10 {
    margin-bottom: 10px;
}

.ml_10 {
    margin-left: 10px;
}

.mlr_10 {
    margin-left: 10px;
    margin-right: 10px;
}

.mtb_10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.m_10 {
    margin: 10px;
}

.mt_15 {
    margin-top: 15px;
}

.mr_15 {
    margin-right: 15px;
}

.mb_15 {
    margin-bottom: 15px;
}

.ml_15 {
    margin-left: 15px;
}

.mlr_15 {
    margin-left: 15px;
    margin-right: 15px;
}

.mtb_15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.m_15 {
    margin: 15px;
}

.mt_20 {
    margin-top: 20px;
}

.mr_20 {
    margin-right: 20px;
}

.mb_20 {
    margin-bottom: 20px;
}

.ml_20 {
    margin-left: 20px;
}

.mlr_20 {
    margin-left: 20px;
    margin-right: 20px;
}

.mtb_20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.m_20 {
    margin: 20px;
}

.mt_30 {
    margin-top: 30px;
}

.mr_30 {
    margin-right: 30px;
}

.mb_30 {
    margin-bottom: 30px;
}

.ml_30 {
    margin-left: 30px;
}

.mlr_30 {
    margin-left: 30px;
    margin-right: 30px;
}

.mtb_30 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.m_30 {
    margin: 30px;
}

.mt_40 {
    margin-top: 40px;
}

.mr_40 {
    margin-right: 40px;
}

.mb_40 {
    margin-bottom: 40px;
}

.ml_40 {
    margin-left: 40px;
}

.mlr_40 {
    margin-left: 40px;
    margin-right: 40px;
}

.mtb_40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.m_40 {
    margin: 40px;
}

.mt_50 {
    margin-top: 50px;
}

.mr_50 {
    margin-right: 50px;
}

.mb_50 {
    margin-bottom: 50px;
}

.ml_50 {
    margin-left: 50px;
}

.mlr_50 {
    margin-left: 50px;
    margin-right: 50px;
}

.mtb_50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.m_50 {
    margin: 50px;
}

.mt_100 {
    margin-top: 100px;
}

.mr_100 {
    margin-right: 100px;
}

.mb_100 {
    margin-bottom: 100px;
}

.ml_100 {
    margin-left: 100px;
}

.mlr_100 {
    margin-left: 100px;
    margin-right: 100px;
}

.mtb_100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

.m_100 {
    margin: 100px;
}

.pt_10 {
    padding-top: 10px;
}

.pr_10 {
    padding-right: 10px;
}

.pb_10 {
    padding-bottom: 10px;
}

.pl_10 {
    padding-left: 10px;
}

.plr_10 {
    padding-left: 10px;
    padding-right: 10px;
}

.ptb_10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.p_10 {
    padding: 10px;
}

.pt_15 {
    padding-top: 15px;
}

.pr_15 {
    padding-right: 15px;
}

.pb_15 {
    padding-bottom: 15px;
}

.pl_15 {
    padding-left: 15px;
}

.plr_15 {
    padding-left: 15px;
    padding-right: 15px;
}

.ptb_15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.p_15 {
    padding: 15px;
}

.pt_20 {
    padding-top: 20px;
}

.pr_20 {
    padding-right: 20px;
}

.pb_20 {
    padding-bottom: 20px;
}

.pl_20 {
    padding-left: 20px;
}

.plr_20 {
    padding-left: 20px;
    padding-right: 20px;
}

.ptb_20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.p_20 {
    padding: 20px;
}

.pt_30 {
    padding-top: 30px;
}

.pr_30 {
    padding-right: 30px;
}

.pb_30 {
    padding-bottom: 30px;
}

.pl_30 {
    padding-left: 30px;
}

.plr_30 {
    padding-left: 30px;
    padding-right: 30px;
}

.ptb_30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.p_30 {
    padding: 30px;
}

.pt_40 {
    padding-top: 40px;
}

.pr_40 {
    padding-right: 40px;
}

.pb_40 {
    padding-bottom: 40px;
}

.pl_40 {
    padding-left: 40px;
}

.plr_40 {
    padding-left: 40px;
    padding-right: 40px;
}

.ptb_40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.p_40 {
    padding: 40px;
}

.pt_50 {
    padding-top: 50px;
}

.pr_50 {
    padding-right: 50px;
}

.pb_50 {
    padding-bottom: 50px;
}

.pl_50 {
    padding-left: 50px;
}

.plr_50 {
    padding-left: 50px;
    padding-right: 50px;
}

.ptb_50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.p_50 {
    padding: 50px;
}

.pt_100 {
    padding-top: 100px;
}

.pr_100 {
    padding-right: 100px;
}

.pb_100 {
    padding-bottom: 100px;
}

.pl_100 {
    padding-left: 100px;
}

.plr_100 {
    padding-left: 100px;
    padding-right: 100px;
}

.ptb_100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.p_100 {
    padding: 100px;
}

.border_bottom {
    border-bottom: 1px solid #cfcfcf;
}

.border_top {
    border-top: 1px solid #cfcfcf;
}

.border_right {
    border-right: 1px solid #cfcfcf;
}

.border_left {
    border-left: 1px solid #cfcfcf;
}

.hide_desktop {
    display: none;
}

.btn_primary {
    background-color: #0b4475 !important;
    border-radius: 10px;
    border: 1px solid #0b4475;
    color: #fff;
    padding: 7px 20px;
    text-align: center;
    box-shadow: none;
    font-size: 16px;
    display: inline-block;
    line-height: 1;
    font-weight: 600;
}

.btn_primary:hover {
    color: #fff;
}

.btn_primary:disabled {
    background-color: #ccc;
    border-color: #ccc;
    cursor: default;
}

.btn_primary_inverted {
    background-color: #eee;
    border-radius: 10px;
    border: 1px solid #eee;
    color: #000;
    padding: 7px 20px;
    text-align: center;
    box-shadow: none;
    font-size: 16px;
    display: inline-block;
    line-height: 1;
    font-weight: 600;
}

.btn_primary_inverted:hover,
.btn_primary_inverted.active {
    background-color: #0b4475;
    border: 1px solid #0b4475;
    color: #fff;
}

.btn_secondary {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #0b4475 !important;
    color: #0b4475;
    padding: 7px 20px;
    text-align: center;
    box-shadow: none;
    font-size: 16px;
    display: inline-block;
    line-height: 1;
    font-weight: 600;
}

.btn_danger {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #dc3545;
    color: #dc3545;
    padding: 7px 20px;
    text-align: center;
    box-shadow: none;
    font-size: 16px;
    display: inline-block;
    line-height: 1;
    font-weight: 600;
}

.btn_success {
    background-color: #28a745;
    border-radius: 10px;
    border: 1px solid #28a745;
    color: #ffffff;
    padding: 7px 20px;
    text-align: center;
    box-shadow: none;
    font-size: 16px;
    display: inline-block;
    line-height: 1;
    font-weight: 600;
}

.btn_tertiary {
    background-color: #ffdd00;
    border-radius: 10px;
    border: 1px solid #ffdd00;
    color: #000;
    padding: 7px 20px;
    text-align: center;
    box-shadow: none;
    font-size: 16px;
    display: inline-block;
    line-height: 1;
    font-weight: 600;
}

.google_btn {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #dfdfdf;
    color: #000;
    padding: 12px 20px;
    text-align: center;
    box-shadow: none;
    font-size: 16px;
    display: inline-block;
    line-height: 1;
    font-weight: 600;
}

.round_btn {
    border-radius: 40px;
}

.full_btn {
    width: 100%;
}

.small_btn {
    font-size: 13px;
    padding: 7px 15px;
}

.big_btn {
    padding: 12px 16px;
    border-radius: 10px;
}

.no-outline {
    outline: none;
}

.icon_btn img {
    display: inline-block;
    width: 22px;
    margin-right: 5px;
}

.btn_text {
    display: inline-block;
}

.btn_icon {
    display: inline-block;
    width: 16px;
}

.btn_icon + .btn_text {
    margin-right: 5px;
}

.btn_text + .btn_icon {
    margin-left: 5px;
}

.link_black {
    color: #000;
    font-weight: 600;
    line-height: 1;
    cursor: pointer;
}

.link_black:hover {
    color: #000;
}

.form_row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.form_col {
    width: calc(50% - 30px);
    margin: 0 15px;
}

.form_col .btn_primary {
    width: 100%;
}

.form-group {
    margin-bottom: 25px;
}

.upload-cv {
    padding-left: 10px;
    padding-right: 10px;
}

.form-control {
    background-color: #eee;
    border-radius: 10px;
    padding: 12px 20px;
    height: auto;
    border: none;
}

.form-control.bordered {
    border: 1px solid #dfdfdf;
}

.form-control:focus {
    background-color: #eee;
    outline: none;
    box-shadow: none;
}

label {
    margin-bottom: 5px;
    font-weight: 600;
}

.form-check-input {
    margin-left: 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 20px;
    height: 20px;
    background-color: #f6f5f5;
    margin-top: 0;
}

.form-check-label {
    margin-left: 30px;
}

.form_input {
    position: relative;
}

.icon_eye {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    z-index: 1;
    cursor: pointer;
}

.close {
    opacity: 1;
    position: absolute;
    opacity: 999;
    right: 15px;
    top: 20px;
    width: 14px;
    text-shadow: none;
}

.section_title {
    display: flex;
    align-items: center;
}

.title_wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.title_icon {
    display: inline-block;
    width: 30px;
    margin-right: 10px;
}

.title_wrapper h3 {
    color: #0b4475;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0;
}

.view_all_link {
    margin-left: auto;
    color: #1e97ff;
    font-weight: 600;
    font-size: 20px;
    width: auto;
    white-space: nowrap;
}

.page_title {
    text-align: center;
    font-size: 30px;
    margin-bottom: 40px;
}

.flx_hcenter {
    justify-content: center;
}

.blue_title {
    color: #0b4475;
    font-size: 30px;
    margin-bottom: 30px;
}

.testimony_swiper .swiper-slide {
    height: auto;
}

.testimony {
    padding: 15px;
    background-color: #f4f4f4;
    border-radius: 5px;
    height: 100%;
}

.testimony h3 {
    color: #1e97ff;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 16px;
}

.testimony p {
    font-size: 13px;
}

.testimony_user {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.testimony_profile {
    width: 50px;
    margin-right: 10px;
}

.testimony_meta {
    font-size: 14px;
    width: calc(100% - 60px);
}

.testimony_meta h4 {
    margin-bottom: 0px;
    font-size: 14px;
}

.testimony_meta p {
    color: #808080;
}

.testimony_swiper .swiper-pagination-bullets {
    bottom: 0;
    position: relative;
    margin: 30px 0 0;
}

.testimony_swiper .swiper-pagination-bullet {
    border: 1px solid #0b4475;
}

.small_banner {
    border-radius: 10px;
    overflow: hidden;
    display: inline-block;
    width: 100%;
}

.slider_container {
    position: relative;
}

.blue_section {
    background-color: #0b4475;
    display: inline-block;
    width: 100%;
}

.blue_section_title {
    color: #fff;
    font-size: 30px;
    font-weight: 800;
}

.blue_section_tabs a {
    font-size: 20px;
    font-weight: 800;
    margin-right: 30px;
    border-bottom: 4px solid #0b4475;
    color: #fff;
    transition: 0.3s all ease;
}

.blue_section_tabs a:hover,
.blue_section_tabs a.active {
    border-color: #fff;
}

.blue_section_tabs a:last-child {
    margin-right: 0;
}

.course_search {
    display: flex;
    align-items: stretch;
}

.course_search .search {
    margin: 0;
}

.course_search .search_input .form-control {
    height: 45px;
}

.course_filter {
    margin-left: 10px;
}

.course_filter .form-control {
    border-radius: 0;
    border: 2px solid #0b4475;
    color: #0b4475;
    font-weight: 600;
    background-color: #fff;
}

.student_course {
    box-shadow: 0 3px 6px #0000003d;
    border-radius: 10px;
    overflow: hidden;
}

.student_course .course_thumbnail {
    margin-bottom: 0;
}

.student_course_inner {
    padding: 15px;
}

.student_course .continue_track {
    height: 5px;
}

.student_course .cc_bar {
    margin-top: 10px;
}

.student_course .continue_bar {
    background-color: #0b4475;
}

.price_wishlist {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.wishprice {
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
}

.wishheart {
    width: 16px;
}

.accordion .card {
    border: none;
}

.accordion_tab {
    font-weight: 600;
    font-size: 18px;
    background-color: #eee;
    cursor: pointer;
    transition: 0.3s all ease;
    position: relative;
}

.accordion_tab::before {
    content: '';
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 8px;
    /* background: url(../img/icons/icon_dropdown_arrow.svg) no-repeat center; */
    background-size: 16px;
    transition: 0.3s all ease;
}

.accordion_tab.collapsed {
    background-color: #fff;
}

.accordion_tab.collapsed:hover {
    background-color: #eee;
}

.accordion_tab.collapsed::before {
    transform: translateY(-50%) rotate(180deg);
}

.accordion .card-body {
    font-size: 14px;
    background-color: #eee;
    padding-top: 0;
}

.content_link {
    color: #808080;
    font-weight: 600;
}

.content_link a {
    color: #1e97ff;
}

/* Checkout CSS */
.cart_wrapper {
    border: 1px solid #0b4475;
    border-radius: 10px;
    padding: 30px 25px;
    margin-bottom: 30px;
}

.cart_wrapper h2 {
    font-size: 18px;
    margin-bottom: 30px;
}

.cart_wrapper .form-group {
    margin-bottom: 15px;
}

.cart_wrapper .form-group:last-child {
    margin-bottom: 0;
}

.cart_wrapper label {
    line-height: 1;
    font-size: 14px;
    font-weight: 500;
}

.cart_wrapper .form-control {
    border: 1px solid #0b4475;
    color: #000;
    background-color: #fff;
    font-size: 14px;
    height: 46px;
}

.cart_wrapper textarea.form-control {
    height: auto;
}

.cart_wrapper .form-control::placeholder {
    color: #9f9f9f;
}

.cart_wrapper .form-control:disabled {
    background-color: #eee;
    border: 1px solid #eee;
    color: #808080;
}

.cart_wrapper .form_input .link {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
}

.cart_wrapper .col {
    margin-bottom: 15px;
}

.ro_wrapper {
    font-size: 14px;
}

.ro_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #dfdfdf;
    font-size: 16px;
}

.ro_bold {
    font-weight: 600;
}

.ro_body {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #dfdfdf;
}

.ro_row {
    margin: 10px 0;
}

.ro_row .link {
    font-size: 13px;
}

.ro_inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 5px;
}

.ro_price {
    white-space: nowrap;
    margin-left: 20px;
}

.ro_subtotal {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #dfdfdf;
}

.ro_subrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
}

.ro_total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 20px;
}

.ro_check {
    margin-bottom: 20px;
}

.pay_method {
    display: flex;
    align-items: center;
    border: 1px solid #0b4475;
    padding: 12px 20px;
    border-radius: 10px;
}

.pay_method label {
    font-weight: 700;
}

.pay_method .form-check-input {
    width: 16px;
    height: 16px;
    border: 2px solid #808080;
}

.no_data {
    font-weight: 600;
}

/* Preloader CSS */
.preloader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999999;
    background-color: #ddd;
}

.preloader_inner {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.preloaderlogo {
    width: 120px;
    margin-bottom: 20px;
}

.preloaderbar {
    width: 200px;
    background: #b3b3b3;
    border-radius: 8px;
}

.preloaderbar::after {
    content: '';
    width: 50px;
    height: 3px;
    background: #0b4475;
    display: block;
    border-radius: 8px;
    animation: animation 1.5s cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
}

.otp-div {
    flex-wrap: nowrap;
}

.otp-input {
    width: 35%;
}

.otp-btns {
    display: flex;
    width: 60%;
}

.verify-btn {
    float: right;
}

.instructor-vr-btn {
    width: 48%;
}

.share-icons {
    width: 40px;
    height: 30px;
}

.btn-icon-add-to-cart {
    position: relative;
    top: -1px;
    width: 18px;
}

.pagination-btns {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #0b4475;
    color: #0b4475;
    /* padding: 7px 15px; */
    text-align: center;
    box-shadow: none;
    font-size: 16px;
    display: inline-block;
    line-height: 1;
    font-weight: 600;
    margin-left: 5px;
    width: 40px;
    height: 32px;
    position: relative;
    top: 1px;
}

.pagination-btn-active {
    background-color: #0b4475;
    color: #fff;
}

.pdf-btn-secondary {
    margin-left: 5px;
}

.cart-item-remove-btn {
    width: 16px;
    background: transparent;
    border: 0;
    padding: 0;
}

@keyframes animation {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(150px);
    }

    100% {
        transform: translateX(0);
    }
}

@-webkit-keyframes loading {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes loading {
    to {
        -moz-transform: rotate(360deg);
    }
}

@-ms-keyframes loading {
    to {
        -ms-transform: rotate(360deg);
    }
}

@keyframes loading {
    to {
        transform: rotate(360deg);
    }
}

.spinner {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: transparent;
    border-top: 2px solid #b3b3b3;
    border-right: 2px solid #b3b3b3;
    border-bottom: 2px solid #0b4475;
    border-left: 2px solid #0b4475;
    -webkit-animation: loading 0.8s infinite linear;
    -moz-animation: loading 0.8s infinite linear;
    -ms-animation: loading 0.8s infinite linear;
    animation: loading 0.8s infinite linear;
}

/* Index 2 */
/* .course_box{border-radius: 10px;border: 1px solid #DFDFDF;overflow: hidden;} */
.box_shadow {
    box-shadow: 0 0 6px #00000021;
    padding: 15px;
    border-radius: 10px;
    background-color: #fff;
}

.category_wrapper {
    background-color: #f4f4f4;
    padding: 100px 0 80px;
}

.category_box {
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: 0 0 6px #00000021;
}

.category_box h4 {
    padding: 20px 5px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
}

/* Bilal */
.react-multi-carousel-list {
    padding: 35px 0 !important;
}

.react-multi-carousel-dot button {
    width: 15px;
    height: 15px;
    border: none;
}

.react-multi-carousel-dot--active button {
    background-color: #0b4475 !important;
}

.carousel .control-dots .dot {
    background-color: #fff !important;
    height: 15px !important;
    width: 15px !important;
}

.carousel .control-dots .selected {
    background-color: #0b4475 !important;
}

.modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 999 !important;
    overflow-y: auto;
}

.react_modal {
    position: absolute;
    top: 10%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, 0);
    border-radius: 20px;
    padding: 0;
    overflow: visible;
    margin-bottom: 30px;
    background-color: #fff;
}

.react-multi-carousel-list {
    padding: 35px 0 !important;
}

.react-multi-carousel-dot button {
    width: 15px;
    height: 15px;
    border: none;
}

.react-multi-carousel-dot--active button {
    background-color: #0b4475 !important;
}

.carousel .control-dots .dot {
    background-color: #fff !important;
    height: 15px !important;
    width: 15px !important;
}

.carousel .control-dots .selected {
    background-color: #0b4475 !important;
}

.modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 999 !important;
    overflow-y: auto;
}

.react_modal {
    position: absolute;
    top: 10%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, 0);
    border-radius: 20px;
    padding: 0;
    overflow: visible;
    margin-bottom: 30px;
    background-color: #fff;
}

.swiper-button-prev,
.swiper-button-next {
    color: #000;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    padding: 12px;
    box-shadow: 0 3px 6px #00000029 !important;
    background: rgba(255, 255, 255, 0.6);
}

.swiper-button-prev:after,
.swiper-button-next:after {
    font-size: 20px;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    left: -20px;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    right: -20px;
}

.swiper-pagination-bullet {
    width: 14px;
    height: 14px;
    display: inline-block;
    border-radius: 100%;
    background: #fff;
    opacity: 1;
}

button.swiper-pagination-bullet {
    border: none;
    margin: 0;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
}

.swiper-pagination-bullet-active {
    opacity: 1;
    background: #0b4475;
}

.react_select .css-yk16xz-control {
    padding: 5px;
    border: 1px solid #0b4374 !important;
    border-radius: 10px !important;
}

.signup-error {
    color: red;
}

.attachment_button {
    position: relative;
}

.attachment_button label {
    cursor: pointer;
    margin: 5px 0px 0px 5px;
}

.attachment_button input[type='file'] {
    opacity: 0;
    z-index: -1;
    position: absolute;
}

.attachment_button input[type='file']:focus + label {
    outline: 0px solid;
}

.attachment_button label img {
    width: 25px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
}

@media screen and (min-width: 768px) and (max-width: 991px) {
}

@media screen and (max-width: 991px) {
    .mb_40 {
        margin-bottom: 20px;
    }

    .mb_50 {
        margin-bottom: 25px;
    }

    .mb_100 {
        margin-bottom: 50px;
    }

    .pt_100 {
        padding-top: 50px;
    }

    .ptb_50 {
        padding-top: 25px;
        padding-bottom: 25px;
    }
}

.Toastify__toast--success {
    background-color: #0b4475 !important;
}

@media screen and (max-width: 767px) {
    body {
        font-size: 14px;
    }

    .ptb_100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .hide_desktop {
        display: block;
    }

    .page_title {
        margin-bottom: 20px;
        font-size: 24px;
    }

    .mobile_fullwidth_swiper {
        margin: 0 -15px;
        width: calc(100% + 30px);
    }

    .mobile_fullwidth_swiper .swiper-wrapper .swiper-slide-active:first-child {
        margin-left: 15px;
    }

    .small_banner {
        margin-bottom: 20px;
    }

    .blue_section_title.mtb_50 {
        margin-top: 25px;
        margin-bottom: 25px;
        font-size: 20px;
    }

    .blue_section_tabs a {
        font-size: 16px;
    }

    .course_search {
        flex-wrap: wrap;
    }

    .course_search .search {
        width: 100%;
    }

    .course_filter {
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
    }
}

.cursor_pointer {
    cursor: pointer;
}

.required-field::after {
    content: '*';
    color: red;
    margin-left: 2px;
}

.hr-line {
    border: 1px solid #0b4475;
}

.note {
    font-family: 'Metropolis', sans-serif;
    font-weight: bold;
    font-size: 14px;
    color: #5c5555;
}

.note span {
    font-weight: 500;
}

.filter_btn {
    position: relative;
    z-index: 9;
}

.dateAickerAbs {
    position: absolute;
    top: 50px;
    right: 0px;
}

.fixing_div {
    position: relative;
    pointer-events: none;
    cursor: default;
}

.text-underline {
    text-decoration: underline;
}

.select-country-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flag-button {
    display: flex;
    flex-flow: column;
    margin-top: 6px;
    margin-right: 6px;
    background-color: #f6f6f6;
    border: none;
    align-items: center;
    justify-content: center;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
}

.chatbot-icon {
    color: #0b4475;
    font-size: 50px;
    transform: scaleX(-1);
}

.chatbot-icon-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.chatbot-icon-container > span {
    font-size: 13px;
    color: #0b4475;
}

.chatbot-icon-container > * {
    transition: 0.2s;
}

.chatbot-icon-container:hover > * {
    color: #7cbbff;
    transition: 0.2s;
}

.flag-button:hover {
    background-color: #7cbbff;
}

/* Default styles (for screens below 1024px) */
.responsive-image {
    width: 100%;
    /* Default to full width */
    height: auto;
}

/* Media query for screens over 768px */
@media (min-width: 769px) {
    .responsive-image {
        width: 66%;
    }
}
