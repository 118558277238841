/********* Start Language Direction ****************/
.i18n-rtl .login-text {
    padding-left: 0px;
    padding-right: 10px;
}
.i18n-rtl .lng-container {
    margin-left: 0px;
    margin-right: 20px;
}
.i18n-rtl .form-group label {
    width: 100%;
    text-align: right;
}
.i18n-rtl .form-group small {
    float: right;
}
.i18n-rtl .form-input input,
.i18n-rtl .form_input input {
    text-align: end;
}
.i18n-rtl .icon_eye {
    right: 0px;
    left: 10px;
    transform: none;
    top: 12px !important;
}
.i18n-rtl select option {
    text-align: left;
}
.i18n-rtl .form-check-label {
    margin-left: 0px;
    margin-right: 30px;
}
.i18n-rtl .form_accept label {
    margin-left: 30px;
}
.i18n-rtl .title_wrapper h3 {
    padding-right: 10px;
}
/* Header Start Here */
.i18n-rtl .search_magnify {
    right: 20px;
    left: unset;
}
.i18n-rtl .search_input .form-control {
    padding: 10px 60px 10px 20px;
}
.i18n-rtl .search_input .reset {
    left: 16px;
    right: unset;
}
.i18n-rtl .pd_ne {
    padding-left: 0px;
    padding-right: 15px;
}
.i18n-rtl .pd_bottom span {
    margin-right: 0px;
    margin-left: 15px;
}
/* Header End Here */

/* Blue Section Start Here */
.i18n-rtl .blue_section .blue_section_title,
.i18n-rtl .blue_title {
    text-align: right;
}
.i18n-rtl .blue_section_tabs {
    display: flex;
}
.i18n-rtl .blue_section_tabs a {
    margin-right: unset !important;
    margin-left: 30px;
}
/* Blue Section End Here */

/* Checkout Start Here */
.i18n-rtl .cart_wrapper h2 {
    text-align: right;
}
.i18n-rtl .cart_wrapper .form_input .link {
    top: 24px;
    right: unset !important;
    left: 10px;
}
.i18n-rtl .cart_wrapper .form_input input {
    text-align: start !important;
}
.i18n-rtl .cart_wrapper .ro_price {
    margin-left: unset !important;
}
/* Checkout End Here */

/* Footer Start Here */
.i18n-rtl .footer_menu {
    text-align: right;
    padding-inline-start: 0px;
}

.i18n-rtl .social_media {
    margin-left: 0px;
    margin-right: auto;
}

.i18n-rtl .footer_contact figure {
    margin-right: 0px;
    margin-left: 10px;
}

.i18n-rtl .footer_box h4,
.i18n-rtl .footer_box p {
    text-align: right;
}

/* Footer End Here */

/* Course Details Start Here */
.i18n-rtl .course_top .course_intro {
    margin-left: unset;
    margin-right: auto;
    padding-left: unset;
    padding-right: 100px;
}
.i18n-rtl .course_intro,
.i18n-rtl .course_subtitle,
.i18n-rtl .course_description,
.i18n-rtl .course_stat,
.i18n-rtl .course_btns,
.i18n-rtl .curriculum_title h2,
.i18n-rtl .curriculum_title span {
    text-align: right;
}
.i18n-rtl .curriculum_title span {
    display: inline-block;
    width: 100%;
}
.i18n-rtl .course_bottom .course_tabs {
    padding-left: unset !important;
    padding-right: 100px;
}
.i18n-rtl .course_tabs .nav-tabs .nav-link {
    margin-right: unset !important;
    margin-left: 20px;
}
.i18n-rtl .course_bottom .course_tabs .nav-tabs {
    padding-right: unset !important;
    padding-left: 40px;
}
.i18n-rtl .course_detail_box .big_btn {
    display: flex;
    justify-content: center;
}
.i18n-rtl .course_detail_box .btn_text {
    order: 1;
}
.i18n-rtl .course_detail_box .btn_text + .btn_icon {
    margin-left: 8px;
}
.i18n-rtl .course_detail_box h6 {
    text-align: right;
}
.i18n-rtl .course_detail_box .social_media button {
    margin-right: unset !important;
    margin-left: 8px;
}
.i18n-rtl .course_stat {
    padding-right: unset !important;
}
.i18n-rtl .course_stat figure {
    margin-right: unset !important;
    margin-left: 10px;
}
.i18n-rtl .content_link {
    text-align: right;
}
/* Course Details End Here */

/* Course Learning Start Here */
.i18n-rtl .page_title .text-left {
    text-align: right !important;
}
.i18n-rtl .all_filters {
    text-align: right;
}
.i18n-rtl .course_topic {
    left: 0px;
    right: unset;
}
.i18n-rtl .topic_chapter,
.i18n-rtl .topic_info h6 {
    text-align: right;
}
.i18n-rtl .topic_info {
    padding-left: unset;
    padding-right: 15px;
}
.i18n-rtl .course_overview .nav-tabs {
    padding-right: unset !important;
}
.i18n-rtl .tab-pane h3,
.i18n-rtl p {
    text-align: right;
}
.i18n-rtl .circle_tick_list {
    padding-right: unset !important;
    padding-left: 40px;
}
.i18n-rtl .circle_tick_list li {
    text-align: right;
    padding-left: unset !important;
    padding-right: 40px;
    background: url(../image/icons/icon_circle_tick.svg) center right no-repeat;
}
/* Course Learning End Here */

/* Course Card Start Here */
.i18n-rtl .course_box_title,
.i18n-rtl .course_box_subtitle,
.i18n-rtl .price_discount {
    text-align: right;
}
.i18n-rtl .course_btns button {
    margin-right: unset !important;
    margin-left: 10px;
}
.i18n-rtl .cc_bar span {
    display: inline-block;
    width: 100%;
    text-align: right;
}
/* Course Card End Here */

/* Cart DropDown Start Here */
.i18n-rtl .cart_course_details {
    padding-left: unset !important;
    padding-right: 15px;
}
.i18n-rtl .cart_course_title,
.i18n-rtl .cart_course_instructor,
.i18n-rtl .dropdown_checkout strong {
    text-align: right;
}
.i18n-rtl .price_discount {
    margin-right: unset !important;
}
.i18n-rtl .terms_condition {
    text-align: right;
}
/* Cart DropDown End Here */

/* Profile Start Here */
.i18n-rtl .profile-container label {
    width: 100%;
    text-align: right;
}
.i18n-rtl .profile-container .save-button-container {
    display: flex;
}
/* Profile End Here */

/* Address Star Here */
.i18n-rtl .add-address-container h2 {
    text-align: right;
}
.i18n-rtl .address_box .category {
    text-align: right;
}
.i18n-rtl .address_box .category .float-right {
    float: left !important;
}
.i18n-rtl .address_box .address {
    text-align: right;
}
/* Address End Here */

/* Privacy Policy Start Here */
.i18n-rtl .privacy-section p {
    text-align: left;
}
/* Privacy Policy End Here */

/* Ask Question Start Here */
.i18n-rtl .askdoubt_modal h2 {
    text-align: right !important;
}
.i18n-rtl .askdoubt_modal .form_input input {
    text-align: start;
}
/* Ask Question End Here */

/* Instructor Start Here */
.i18n-rtl .country-select,
.i18n-rtl .country-select option {
    text-align: right;
}
/* Instructor End Here */

/* My Order Start Here */
.i18n-rtl .my-order-section h3,
.i18n-rtl .my-order-section th,
.i18n-rtl .my-order-section td {
    text-align: right;
}
/* My Order End Here */

/* Notification Start Here */
.i18n-rtl .notify_dropdown h3,
.i18n-rtl .notify_dropdown .no-data {
    text-align: right;
}
.i18n-rtl .notify_dropdown .notify_content {
    text-align: right;
}
/* Notification End Here */

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .course_intro,
    .course_tabs.pl_100 {
        padding-left: unset;
        padding-right: 50px;
    }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
    .i18n-rtl .lng-container {
        margin-left: 20px;
        margin-right: 0px;
    }
}

/********* End Language Direction ****************/
