/* src/components/CourseLearning/Course/Unavailable.css */
.unavailable {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 300px; */
    /* height: 100%; */
    height: 446px;
    text-align: center;
    padding: 20px;
    background: var(--light-gray);
    border-radius: 8px;
    gap: 12px;
}

.unavailable h2 {
    color: var(--primary);
    /* margin-bottom: 12px; */
    font-weight: bold;
}

.unavailable p {
    color: var(--secondary);
    font-weight: bold;
}
