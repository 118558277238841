.course-home {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 50px;
}

.course-home button {
    background-color: #0B4475;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: 0.3s;
}

.course-home button:hover {
    background-color: #1580DC;
    transition: 0.3s;
}

@media (max-width: 965px) {
    .course-home {
        padding: 0 !important;
        justify-content: space-around;
        margin-top: 20px;
    }

    .course-home button {
        margin-bottom: 15px;
        margin-top: 15px;
    }
}

@media (max-height: 569px) {
    .course-home {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}