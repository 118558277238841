.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    width: 100%;
    /* height: 100%; */
    min-height: 386px;
    border: 1px solid var(--dark-gray-20);
    gap: 12px;
}

.error-container h2 {
    color: var(--error);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    /* margin-bottom: 12px; */
}

.error-container button {
    background-color: var(--light-blue);
    color: var(--white);
    border: none;
    border-radius: 60px;
    padding: 8px 16px;

    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
}

.error-container button:hover {
    background-color: var(--light-blue-80);
}
