.quiz-favorites-container {
    width: 100%;
    height: 100%;
}

.quiz-favorites-container > h1 {
    /* color: #0B4475; */
    font-weight: bold;
    word-wrap: break-word;
    font-size: 25px;
}

.quiz-favorites-list {
    width: 100%;
    height: 95%;
    overflow-y: scroll;
}

.quiz-favorites-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border: 1px solid #0B4475;
    text-align: initial !important;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
}

.quiz-favorites-item-votes {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.quiz-favorites-item-label {
    font-weight: bold;
    color: #0B4475;
}

.correct-answer {
    color: limegreen;
}

.incorrect-answer {
    color: red;
}

@media (max-height: 865px) {
    .quiz-favorites-list {
        height: 96% !important;
    }
}