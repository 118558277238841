/* src/components/common/TextLoadingAnimation.css */
.text-loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    min-height: 446px;
    height: 100%;
}

.text-loading-container__title {
    position: relative;
    background: linear-gradient(90deg, rgba(19, 138, 180, 1) 50%, rgba(19, 138, 180, 0.8) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    /* animation: text-shimmer 1.5s infinite; */
    /* animation: text-shimmer 2s linear infinite; */
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
}

.loading-line {
    /* background: #f0f0f0; */
    background: var(--primary-30);
    border-radius: 40px;
    overflow: hidden;
    position: relative;
}

.loading-line::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        /* 90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 50%,
        rgba(255, 255, 255, 0) 100% */
            90deg,
        rgba(19, 138, 180, 0) 0%,
        rgba(19, 138, 180, 0.8) 50%,
        rgba(19, 138, 180, 0) 100%
    );
    animation: shimmer 1.5s infinite;
}

/* Circle Loading */
.circle-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 446px;
    height: 100%;
    /* border: 1px solid red; */
}

.circle-loading__spinner {
    border-radius: 50%;
    border: 16px solid var(--primary-10);
    border-top-color: var(--primary);
    animation: rotate 1s linear infinite;
}

/* Circle Sizes */
.circle-loading.small .circle-loading__spinner {
    width: 24px;
    height: 24px;
}

.circle-loading.medium .circle-loading__spinner {
    width: 40px;
    height: 40px;
}

.circle-loading.large .circle-loading__spinner {
    width: 56px;
    height: 56px;
}

/* Different variants for different contexts */
.text-loading-container.chat-message {
    padding: 12px;
    background: var(--light-blue);
    border-radius: 16px;
}

.text-loading-container.card {
    padding: 16px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Flashcard Loading Styles */
.flashcard-loading {
    background: var(--light-blue);
    border-radius: 8px;
    padding: 20px;
    height: 400px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.flashcard-loading__header {
    padding: 16px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
}

.flashcard-loading__content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.flashcard-text {
    width: 80%;
    margin: 0 auto;
}

/* Quiz Loading Styles */
.quiz-loading {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.quiz-loading__question {
    padding: 16px;
    background: #f5f5f5;
    border-radius: 8px;
}

.quiz-loading__options {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.option-loading {
    padding: 12px;
    background: #f9f9f9;
    border-radius: 6px;
}

/* @keyframes text-shimmer {
    0% {
        background-position: -20% center;
    }
    100% {
        background-position: 20% center;
    }
} */

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
