.ask-instructor {
    /* border: 1px solid green; */
    /* position: relative; */
    display: flex;
    flex-direction: column;
    height: 100%; /* Fill parent height */
    width: 100%; /* Fill parent width */
    position: relative;
    background: #fff;
}

.ask-instructor__header {
    flex-shrink: 0; /* Prevent header from shrinking */
    display: flex;
    align-items: center;
    padding: 14px;
    border-bottom: 1px solid #ccc;
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 10;
}
.ask-instructor__profile {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 6px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.ask-instructor__profile-image {
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;

    border-radius: 100%;
    background-color: #1392be;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 800;
}

.ask-instructor__profile h1 {
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    color: var(--primary);
}

.ask-instructor__back-button {
    cursor: pointer;
    border: none;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    background-color: white;
    /* color: var(--primary-100); */
    /* margin-right: 20px; */

    border: 1px solid var(--primary-10);
}

.ask-instructor__back-button:hover {
    background-color: var(--light-blue);
    color: #fff;
}

.msg_win {
    flex: 1; /* Take up remaining space */
    overflow-y: auto;
    padding: 16px;
    position: relative;

    /* Hide scrollbar while keeping functionality */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */

    scroll-behavior: smooth;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
}

.ask-instructor__reply-text {
    color: #fff;
    background-color: var(--secondary);
    border-radius: 16px 16px 16px 0px;
    padding: 12px 12px 4px 12px;
    width: fit-content;
    margin-bottom: 12px;
}

.ask-instructor__send-text {
    color: #fff;
    margin-left: auto;
    padding: 12px;
    width: fit-content;
    background-color: var(--light-blue);
    border-radius: 16px 16px 0px 16px;
    margin-bottom: 12px;
}

.ask-instructor__reply-text p,
.ask-instructor__send-text p {
    font-size: 16px;
    margin-bottom: 4px;
}

.send_date,
.reply_date {
    font-weight: 300;
    color: var(--white-10);
    font-size: 12px;
    text-align: right;
}

.ask-instructor__menu {
    flex-shrink: 0; /* Prevent menu from shrinking */
    border-top: 1px solid #ccc;
    background: #fff;
    position: sticky;
    bottom: 0;
    z-index: 10;
}
