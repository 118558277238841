.course {
    height: 100%;
    width: 100%;
}

.course-content {
    height: 90%;
    width: 100%;
}

.course-header {
    display: flex;
    align-items: center;
}

.course-header > h1 {
    /* color: #0B4475; */
    font-weight: bold;
    word-wrap: break-word;
    font-size: 25px;
}

.course-back-icon {
    color: #0B4475;
    cursor: pointer;
    margin-right: 10px;
}

@media (max-height: 702px) {
    .course-content {
        overflow-y: scroll;
        height: 93% !important;
    }

   .course-content::-webkit-scrollbar {
        width: 8px;
        display: block;
    }
    
   .course-content::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
    }
    
   .course-content::-webkit-scrollbar-thumb {
        background: #0B4475;
        border-radius: 10px;
    }
}

@media (max-height: 519px) {
    .course-content {
        height: 90% !important;
    }
}