.course-flashcards {
    height: 100%;
    width: 100%;
}

.course-flashcards > h1,
.course-flashcards > h2 {
    /* color: #0B4475; */
    font-weight: bold;
    word-wrap: break-word;
    margin-top: 20px;
    font-size: 20px;
}

.flashcard-create {
    display: flex;
    flex-direction: column;
    width: 90%;
}

.flashcard-create-question,
.flashcard-create-answer {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.flashcard-create-question > span,
.flashcard-create-answer > span {
    margin-right: 10px;
    font-weight: bold;
    color: #0B4475;
    font-size: 24px;
    width: 25px;
}

.flashcard-create-question > input,
.flashcard-create-answer > textarea {
    width: 100%;
    border: 1px solid #0B4475;
    border-radius: 5px;
    padding: 10px;
}

.flashcard-create-footer > button {
    background-color: #0B4475;
    color: white;
    padding: 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    align-self: flex-end;
}

.flashcard-list {
    overflow-y: scroll;
    height: 48%;
}

.flashcard-list-item-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #0B4475;
    padding: 10px;
    border-radius: 5px;
    width: 90%;
}

.flashcard-list-item {
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    margin-bottom: 5px;
    margin-top: 15px;
    width: 100%;
}

.flashcard-list-item-question,
.flashcard-list-item-answer {
    font-weight: bold;
    color: #0B4475;
    font-size: 16px;
}

.flashcard-list-item-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    margin-left: 10px;
}

.flashcard-list-item-actions > * {
    background-color: #0B4475;
    color: white;
    padding: 5px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    width: 50px;
}

.flashcard-create-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
}

.flashcard-create-footer > select {
    width: 70%;
    border: 1px solid #0B4475;
    border-radius: 5px;
    padding: 10px;
    height: 45.59px;
    margin-right: 20px;
}

.flashcard-list-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 49%;
}

.flashcard-list-empty > h3 {
    /* color: #0B4475; */
    font-size: 24px;
}

.swal2-input {
    border: 1px solid #0B4475;
    width: 80%;
}

.custom-swal-title {
    color: #0B4475;
}

.fetch-flashcards-button {
    background-color: #0B4475;
    color: white;
    padding: 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    align-self: flex-end;
    margin-top: 30px;
    /* display: none; */
}

@media (max-width: 965px) {
    .flashcard-list-empty {
        margin-top: 50px;
    }
}

@media (max-height: 865px) {
    .flashcard-list {
        height: 60% !important;
    }
}

@media (max-height: 822px) {
    .flashcard-list {
        height: 55% !important;
    }
}

@media (max-height: 748px) {
    .flashcard-list {
        height: 52% !important;
    }
}

@media (max-height: 715px) {
    .flashcard-list {
        height: 50% !important;
    }

    .flashcard-list-container {
        max-height: 50vh !important; /* Limits height to 70% of viewport height */
        overflow-y: auto; /* Enables scrolling */
        padding: 10px;
    }
}

@media (max-height: 697px) {
    .flashcard-list {
        height: 48% !important;
    }
}

@media (max-height: 673px) {
    .flashcard-list {
        height: 45% !important;
    }
}

@media (max-height: 652px) {
    .flashcard-list {
        height: 42% !important;
    }
}

@media (max-height: 618px) {
    .flashcard-list {
        height: 40% !important;
    }
}

@media (max-height: 608px) {
    .flashcard-list {
        display: none !important;
    }

    /* .fetch-flashcards-button {
        display: block !important;
    } */
}

.large-swal-container {
    margin: 20px auto !important; /* Adds margin to top and bottom */
}

/* If you want the content to be scrollable when it's too long */
.flashcard-list-container {
    max-height: 70vh; /* Limits height to 70% of viewport height */
    overflow-y: auto; /* Enables scrolling */
    padding: 10px;
    text-align: left;
}

.flashcard-actions button:hover {
    opacity: 0.8;
    transform: scale(1.1);
    transition: all 0.2s ease;
}

.flashcard-item {
    text-align: left;
    margin-bottom: 10px;
}

/* Override any SweetAlert2 default styles */
.swal2-html-container {
    text-align: left !important;
}

.flashcard-details {
    text-align: left;
    margin-bottom: 20px;
}