.quiz_box {
    /* background-color: red; */
    width: 100%;
    /* height: 100%; */
    height: 780px;
    min-height: 446px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 1px solid #0b4474; */
}

.quiz_box p {
    margin-bottom: 0;
}

.quiz_question {
    width: 100%;
}

.quiz_count {
    width: 100%;
}

.quiz_options {
    display: flex;
    flex-direction: column;

    /* gap: 10px; */
}
.quiz_options label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.quiz_options input {
    cursor: pointer;
    /* Default styling */
    width: 14px;
    height: 14px;
    border: 1px solid #ccc;
    /* border-radius: 0px; */
    /* padding: 8px; */
    /* transition:
        border-color 0.3s ease,
        background-color 0.3s ease; */
}

.quiz_box .btn_primary {
    /* margin-top: 40px; */
    border-radius: 0;
}

.text-right {
    text-align: left !important;
}

.quiz_answer {
    width: 100%;
}

.quiz_result {
    width: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.quiz_result_score_container {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.quiz_result_title {
    font-weight: 500;
    text-transform: capitalize;
    font-size: 16px;
    color: var(--primary);
}

.quiz_result_score {
    font-weight: bold !important;
    text-transform: capitalize;
    font-size: 32px;
    color: var(--primary);
}

.quiz_result_score_number {
    font-weight: bold;
}

.underline {
    text-transform: capitalize;
    font-size: 16px !important;
    color: var(--primary) !important;
    text-decoration: underline !important;
}
