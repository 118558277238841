.course-student-progress {
    /* border: 1px solid red; */
    width: 100%;
    height: 100%;
}

.course-student-progress > h1 {
    /* color: #0B4475; */
    font-weight: bold;
    font-size: 20px;
    margin-top: 10px;
}

.course-student-progress-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    overflow-y: scroll;
    height: 90%;
    overflow-x: hidden;
}

.course-student-progress-item {
    border: 1px solid #0B4475;
    padding: 10px;
    border-radius: 5px;
    width: 98%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.course-student-progress-item > button {
    background-color: #0B4475;
    color: white;
    border: none;
    padding: 5px;
    border-radius: 5px;
    width: 80px;
}

.course-student-progress-item-name {
    font-weight: bold;
    /* color: #0B4475; */
    width: 100px;
}

.course-student-progress-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: 98%;
}

.course-student-progress-list-header > span {
    width: 80px;
    text-align: center;
    font-weight: bold;
    /* color: #0B4475; */
}

.course-student-progress-item > * {
    width: 80px;
    text-align: center;
}

@media (max-width: 669px) {
    .course-student-progress-list-header,
    .course-student-progress-item-lesson,
    .course-student-progress-item-flashcards,
    .course-student-progress-item-quiz,
    .course-student-progress-item-mock-exam,
    .course-student-progress-item-overall-progress {
        display: none;
    }

    .course-student-progress-item {
        width: 80%;
    }

    .course-student-progress-list {
        align-items: center;
    }

    .course-student-progress-item-view-button {
        display: initial !important;
    }
}

@media (max-width: 440px) {
    .course-student-progress-item {
        width: 98%;
    }
}

@media (max-width: 349px) {
    .course-student-progress-item {
        flex-direction: column;
        width: 70%;
    }

    .course-student-progress-item-view-button {
        margin-bottom: 10px;
    }
}

.progress-details {
    text-align: left;
    padding: 20px;
    width: 100%;
    overflow-x: hidden;
}

.progress-item {
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.progress-item span {
    white-space: nowrap;
    min-width: 50px;
    text-align: right;
}

.progress-item strong {
    word-break: normal;
    word-wrap: break-word;
    hyphens: auto;
    margin-right: 20px;
}

.custom-swal-title {
    color: #0B4475 !important;
}

.course-student-progress-no-progress {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.course-student-progress-no-progress > h1 {
    /* color: #0B4475; */
}

@media (max-width: 322px) {
    .swal2-html-container {
        margin: 0 !important;
    }
}

@media (max-width: 262px) {
    .progress-item strong {
        font-size: 14px;
        margin-right: 5px !important;
    }

    .custom-swal-title {
        padding: 20px 0 0 0 !important;
    }

    .progress-item span {
        font-size: 12px;
    }
}