#chat-container {
    margin: 0px;
    height: fit-content;
}

#chat-window {
    /* height: 200px; */
    height: 25dvh !important;
}

@media (max-width: 965px) {
    #chat-window {
        height: 350px !important;
    }
}

@media (max-height: 1129px) {
    #chat-window {
        height: 25dvh;
    }
}

@media (max-height: 968px) {
    #chat-window {
        height: 20dvh;
    }
}

@media (max-height: 623px) {
    #chat-window {
        height: 150px;
    }
}

@media (max-height: 865px) {
    #chat-window {
        height: 40dvh;
    }
}

@media (max-height: 752px) {
    #chat-window {
        height: 30dvh;
    }
}

@media (max-height: 597px) {
    #chat-window {
        height: 22dvh;
    }
}

@media screen and (min-width: 966px) and (max-height: 695px) {
    #chat-window {
        height: 25dvh !important;
    }
}