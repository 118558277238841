.report-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.report-modal {
    background-color: white;
    padding: 24px;
    border-radius: 0px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.report-modal__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    position: relative;
    /* border: 1px solid red; */
}

.report-modal__header h2 {
    margin: 0;
    /* font-size: 20px;
    color: #333; */
    font-size: 18px;
    text-transform: uppercase;
    color: var(--dark-gray);
    font-weight: 700;
}

.close-icon {
    position: absolute;
    top: 0;
    right: 0;
}

.report-modal__description {
    color: var(--dark-gray-40);
    margin-bottom: 16px;
    text-align: center;
    font-size: var(--font-size-12);
    font-weight: 700;
}

.report-modal__textarea {
    width: 100%;
    min-height: 150px;
    padding: 12px;
    /* border: 1px solid #ddd; */
    /* border-radius: 4px; */
    border: none;
    border-radius: 0;
    margin-bottom: 16px;
    resize: vertical;
    font-family: inherit;
    background-color: var(--dashboard-section-bg);
    color: var(--dark-gray-40);
    resize: none;
}

.report-modal__textarea:focus {
    outline: 2px solid #0b4374; /* Change the color and thickness as needed */
    border-color: #0b4374; /* Optional: change border color on focus */
}

.report-modal__submit {
    background-color: #0b4374;
    color: white;
    border: none;
    padding: 10px 24px;
    border-radius: 0;
    cursor: pointer;
    font-size: 14px;
    width: 100%;
    transition: background-color 0.2s;
}

.report-modal__submit:hover {
    background-color: #083058;
}
