.all-reported-issues-container {
    height: 100%;
    /* margin-top: 20px; */
    width: 100%;
}

.all-reported-issues-container > h1 {
    /* color: #0B4475; */
    font-weight: bold;
    word-wrap: break-word;
    font-size: 20px;
}

.reported-issues-container {
    display: flex;
    flex-direction: column;
    height: 96%;
    width: 100%;
    overflow-y: scroll;
}

.reported-issue {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #0B4475;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    width: 95%;
}

.reported-issue > button {
    background-color: #0B4475;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.reported-issue > button:hover {
    background-color: #1580DC;
    color: white;
}

.reported-issue > span {
    width: 100px;
    text-align: center;
}

.reported-issue-user {
    /* color: #0B4475; */
    font-weight: bold;
}

.reported-issues-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
}

.reported-issues-empty > h3 {
    /* color: #0B4475; */
    font-size: 24px;
}

.progress-details {
    text-align: left;
    padding: 10px 20px;
}

.progress-item {
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.progress-item strong {
    word-break: normal;
    word-wrap: break-word;
    hyphens: auto;
    margin-right: 20px;
    min-width: 150px;
    color: #0B4475;
}

.progress-item span {
    flex: 1;
    text-align: right;
    word-break: break-word;
    white-space: normal;
    padding-left: 10px;
}

.quiz-divider {
    margin: 20px 0;
    border: 0;
    border-top: 2px solid #0B4475;
}

.quiz-list-container {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.custom-swal-title {
    color: #0B4475 !important;
}

/* If you want the content to be scrollable when it's too long */
.progress-details {
    max-height: 70vh; /* Limits height to 70% of viewport height */
    overflow-y: auto; /* Enables scrolling */
    padding: 10px;
}

@media (max-width: 488px) {
    .reported-issue {
        flex-direction: column;
        width: 70%;
        gap: 20px;
    }

    .reported-issues-container {
        align-items: center;
    }
}

@media (max-width: 490px) {
    .swal2-html-container {
        margin: 0 !important;
    }

    .progress-details {
        padding: 0 !important;
    }
}

@media (max-width: 407px) {
    .quiz-details {
        padding: 0 !important;
    }

    .progress-item span {
        white-space: normal;
        width: fit-content !important;
        min-width: 0 !important;
        font-size: 14px !important;
        padding-left: 0 !important;
    }

    .progress-item strong {
        margin-right: 0 !important;
        width: fit-content !important;
        min-width: 0 !important;
        font-size: 14px !important;
        word-break: break-word;
    }
}

@media (max-height: 715px) {
    .progress-details {
        max-height: 50vh !important; /* Limits height to 70% of viewport height */
        overflow-y: auto; /* Enables scrolling */
        padding: 10px;
    }
}