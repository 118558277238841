.about-section {
    background-color: #ecfaff;
    padding: 90px 0px 0px 0px;
}
.about-section .title {
    font-size: 40px;
    margin-bottom: 40px;
    font-weight: 800;
    width: 300px;
}
.about-section img {
    width: auto;
    max-height: 360px;
}
.view-courses-button {
    padding: 12px 32px;
}
.join-button {
    padding: 12px 48px;
}
.our_team .team_card {
    padding: 32px;
    height: 100%;
    box-shadow: 5px 5px 20px 0 rgb(0 0 0 / 15%);
    border-radius: 50px;
    background-color: #eeeeee95;
}
.our_team .team_card:hover {
    box-shadow: 0 0 24px #138ab4;
    border-radius: 50px;
    cursor: pointer;
}
.our_team .profile-container {
    display: flex;
    justify-content: center;
}
.our_team .profile {
    border-radius: 15px;
    width: 100%;
    border-radius: 50%;
}
.our_team .profile_details {
    padding: 0 16px 16px;
}
.our_team h3 {
    text-align: center;
    font-size: 16px;
    margin-bottom: 16px;
    margin-top: 16px;
    font-weight: bold;
}
.our_team .bio {
    text-align: center;
    margin-bottom: 0px;
}
